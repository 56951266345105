<template>
  <div class="v-card">
    <v-arrow-bg :showHeader="false">
      <div class="v-card-container">
        <div
          class="count"
          v-for="i in controlList"
          :key="'control_' + i.id"
          @click="clickControl(i)"
        >
          <div
            class="value"
            :class="[ctrlId == i.id ? 'active' : '', 'img' + i.id]"
          ></div>
          <div class="label">{{ i.label }}</div>
        </div>
      </div>
    </v-arrow-bg>
  </div>
</template>

<script lang="js">
import VArrowBg from "../v-arrow-bg.vue";
export default {
  name: 'VLeftCard2',
  components: {
    VArrowBg
  },
  props: {
    propsData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  watch: {
    propsData: {
      deep: true,
      handler() {
        this.screenData = Object.assign({}, this.propsData);
      }
    }
  },
  data() {
    return {
      screenData: {},
      ctrlId: 0,
      controlList: [
        {
          id: 0,
          label: "开机",
          mode: "kaiji"
        },
        {
          id: 1,
          label: "离家",
          mode: "lijia"
        },
        {
          id: 2,
          label: "调试",
          mode: "tiaoshi"          
        }
      ]
    }
  },
  mounted() {
    this.clickControl(this.controlList[0]);
  },
  methods: {
    clickControl(i) {
      this.ctrlId = i.id;
      this.$emit("control", i)
    },
  }
}
</script>
<style scoped lang="scss">
.v-card {
  width: 100%;
  height: 126px;
  border-radius: 2px;
  .v-card-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .count {
      cursor: pointer;
    }
    .label {
      font-family: "microsoft yahei ui","microsoft yahei";
      font-size: 12px;
      line-height: 1;
      text-align: center;
      background: linear-gradient(
        180deg,
        rgba(228, 254, 255, 0.7) 0%,
        rgba(237, 254, 255, 0.7) 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }

    .value {
      width: 50px;
      height: 50px;
      line-height: 50px;
      font-family: "microsoft yahei ui","microsoft yahei";
      font-size: 18px;
      font-weight: 500;
      color: #d9fdee;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      background-repeat: no-repeat;
      background-size: 100% 100%;

      &.img0 {
        background-image: url("../../images/left/kaiji.png");
        &:hover {
          background-image: url("../../images/left/kaiji-hover.png");
        }
        &.active {
          background-image: url("../../images/left/kaiji-active.png");
        }
      }

      &.img1 {
        background-image: url("../../images/left/lijia.png");
        &:hover {
          background-image: url("../../images/left/lijia-hover.png");
        }
        &.active {
          background-image: url("../../images/left/lijia-active.png");
        }
      }

      &.img2 {
        background-image: url("../../images/left/tiaoshi.png");
        &:hover {
          background-image: url("../../images/left/tiaoshi-hover.png");
        }
        &.active {
          background-image: url("../../images/left/tiaoshi-active.png");
        }
      }
    }
  }
}
</style>
