// modUrl:模型名称
// position：模型位置(x,y,z)
export function loaderModel (sc,modUrl,position) {
  return new Promise(resolve => {
    let urls = [
      {
        type: "gltf",
        url: "https://res-more.deebike.com/models/"+modUrl,
        onLoad: (object) => {
          console.log("-----", object)
          const model = object.scene;
          model.scale.set(1, 1, 1);
          model.position.x = position.x;

          model.position.y = position.y;

          model.position.z = position.z;
          // model.rotation.set(0,-Math.PI / 2,  0);

          sc.scene.add(object.scene)
          document.getElementById("container2").style.display = 'none';


          
          // 给个别模型配置阴影
          // console.log(model)
          // const receiveModel = ['mesh_0', 'mesh_6'];
          // model.traverse(obj => {
          //   console.log("obj---", obj.name)
          //   console.log("obj---", obj, 'name:', obj.name)
          //   if (receiveModel.includes(obj.name)) {
          //     obj.receiveShadow = true;
          //   } else {
          //     obj.castShadow = true;
          //     obj.receiveShadow = false;
          //   }
          // })

        }
      }
    ];


    sc.iterateLoad(urls, null, () => {})

    resolve()
  })
}