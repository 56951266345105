<template>
  <v-card-bg>
    <div class="v-card">

      <div class="vc-title">
        <span><v-title-bg>温度趋势</v-title-bg></span>
      </div>
      
      <div class="vc-chart">
        <mr-echarts :options="options" />
      </div>
    </div>
    
  </v-card-bg>
</template>

<script lang="js">

import MrEcharts from "@/components/public/mr-echarts.vue";
import VCardBg from "./v-card-bg.vue";
import VTitleBg from "./v-title-bg.vue";

export default {
  name: 'VCard',
  components: {
    VCardBg,
    VTitleBg,
    MrEcharts
  },
  props: {
    propsData: {
      type: Object, 
      default: () => {
        return {}
      }
    }
  },
  watch: {
    propsData: {
      deep: true,
      handler() {
        this.screenData = Object.assign({}, this.propsData);
        this.init()
      }
    }
  },
  data() {
    return {
      screenData: {},
      options: {}
    }
  },
  mounted() {
  },
  methods: {
    init() {
      const tempList = this.screenData.tempList || [];
      const axis = tempList.map(i => i.axis);
      const value1 = tempList.map(i => i.value1);
      const value2 = tempList.map(i => i.value2);
      setTimeout(() => {
        this.options = {
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['温度', '湿度'],
            icon: "rect",
            itemWidth: 10,
            itemHeight: 10,
            right: 0,
            textStyle: {
            color: "#fff"
            }
          },
          grid: {
            top: "20%",
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: axis,
            axisLabel: {
              color: "#fff",
            },
            axisLine: {
              lineStyle: {
                type: "dashed",
                color: "#fff",
                opacity: 0.4
              }
            },
            axisTick: {
              show: false
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                opacity: 0.4
              }
            }
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              color: "#fff",
              rotate: 30
            },
            axisLine: {
              show: false
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                opacity: 0.4
              }
            }
          },
          series: [
            {
              name: '温度',
              type: 'line',
              stack: 'Total',
              data: value1,
              itemStyle: {
                color: "#02FFFF"
              }
            },
            {
              name: '湿度',
              type: 'line',
              stack: 'Total',
              data: value2,
              itemStyle: {
                color: "#FFBB32"
              }
            },
          ]
        };
      }, 10)
    }
  }
}
</script>
<style scoped lang="scss">
.v-card {
  height: 221.27px;
  padding-top: 12px;
  box-sizing: border-box;
  .vc-title {
    padding-left: 24px;
  }
  >.vc-chart {
    width: 100%;
    height: 184px;
  }
}
</style>
