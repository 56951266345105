import { render, staticRenderFns } from "./v-opt-dialog.vue?vue&type=template&id=176f6bdf&scoped=true"
import script from "./v-opt-dialog.vue?vue&type=script&lang=js"
export * from "./v-opt-dialog.vue?vue&type=script&lang=js"
import style0 from "./v-opt-dialog.vue?vue&type=style&index=0&id=176f6bdf&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "176f6bdf",
  null
  
)

export default component.exports