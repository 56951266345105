import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GUI } from 'three/examples/jsm/libs/lil-gui.module.min.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
//导入hdr图像加载器
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";//rebe加载器

// 效果制作器
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
// 渲染通道
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
// 发光描边OutlinePass
import { OutlinePass } from "three/examples/jsm/postprocessing/OutlinePass.js";



export default class DThree {
  constructor(id, options, proid) {
    this.id = id;
    this.options = options;
    this.el = document.getElementById(id);
    this.percentDiv = document.getElementById(proid);
    // this.percentDiv.style.width = 0.8 * 400 + "px";//进度条元素长度
    // this.percentDiv.style.textIndent = 0.8 * 400 + 5 + "px";//缩进元素中的首行文本
    // this.percentDiv.innerHTML = "80%";//进度百分比
    this.animationFrame;
    this.animationFram;
    this.animationFrame;
    this.animationFram;
    this.animateClipAction = null;
    this.animationColock = new THREE.Clock();
    // 动画循环方式枚举
    this.loopMap = {
      LoopOnce: THREE.LoopOnce, // 只播放一次
      LoopRepeat: THREE.LoopRepeat,//循环播放
      LoopPingPong: THREE.LoopPingPong // 来回播放
    };
    this.model;
  }

  initThree() {
    this.width = this.el.offsetWidth;
    this.height = this.el.offsetHeight;

    this.createScene();
    this.createCamera();
    this.createRenderer();
    this.el.appendChild(this.renderer.domElement);
    this.createGui();
    this.resizeWin();
  }

  // 初始化坐标
  initHelper() {
    // this.scene.add(new THREE.AxesHelper(100));
  }

  // 初始化控制器
  initControl() {

    const control = new OrbitControls(this.camera, this.renderer.domElement);
    control.enableDamping = true;
    control.enableZoom = true;
    control.enableRotate = true;
    control.autoRotateSpeed = 0.5;
    control.enablePan = true;
    this.control = control;
    control.setAngle = function (phi, theta, distance) {

      var r = distance || control.object.position.distanceTo(control.target);

      var x = r * Math.cos(phi - Math.PI / 2) * Math.sin(theta) + control.target.x;
      var y = r * Math.sin(phi + Math.PI / 2) + control.target.y;
      var z = r * Math.cos(phi - Math.PI / 2) * Math.cos(theta) + control.target.z;

      control.object.position.set(x, y, z);

      control.object.lookAt(control.target);

    };

    control.addEventListener('change', () => {
      // 浏览器控制台查看相机位置变化
      // console.log('camera.position', this.camera.position);
      // this.camera.position.set(200, 200, 200);
    });
  }

  // 初始化灯光
  initLight() {
    let ambientLight = new THREE.AmbientLight(0xffffff, 3); //设置环境光

    this.scene.add(ambientLight);


    // 加载hdr环境图
    let rgbeLoader = new RGBELoader();
    //资源较大，使用异步加载
    rgbeLoader.loadAsync("https://res-more.deebike.com/models/demo.hdr").then((texture) => {
      texture.mapping = THREE.EquirectangularReflectionMapping;
      //将加载的材质texture设置给背景和环境
      //  this.scene.background = texture;
      //  this.scene.environment = texture;
    });

    // 光源辅助观察
    // const pointLightHelper = new THREE.PointLightHelper(0xffffff, 10);
    // this.scene.add(pointLightHelper);


    const directionLight = new THREE.DirectionalLight(0xffffff, 2);
    directionLight.castShadow = true;
    directionLight.position.set(10, 10, 10);
    const pointLight = new THREE.PointLight(0xffffff, 7670000);
    //点光源位置
    pointLight.position.set(-882, 104, -76);//点光源放在Y轴上

    this.scene.add(pointLight);

    this.scene.add(directionLight);
    this.gui.add(ambientLight, 'intensity', 0, 22.0);
    this.gui.add(directionLight, 'intensity', 0, 22.0);
    this.gui.add(pointLight, 'intensity', 0, 10000000);
    this.gui.add(pointLight.position, 'x', -1000, 1000);
    this.gui.add(pointLight.position, 'y', -1000, 1000);
    this.gui.add(pointLight.position, 'z', -1000, 1000);

    // this.gui.add(pointLight.position, 'y', -100, 100);


    // 投影范围
    directionLight.shadow.mapSize.width = 1024;
    directionLight.shadow.mapSize.height = 1024;
    directionLight.shadow.camera.near = 10; // 近地面
    directionLight.shadow.camera.far = 300; // 远地面
    directionLight.shadow.camera.top = 100;
    directionLight.shadow.camera.bottom = -100;
    directionLight.shadow.camera.left = -100;
    directionLight.shadow.camera.right = 100;
    directionLight.shadow.camera.bias = 0.05;
    directionLight.shadow.camera.normalBias = 0.05;

    // 模拟相机视锥体
    // const helper = new THREE.CameraHelper(directionLight.shadow.camera);
    // this.scene.add(helper);

  }

  // 加载模型
  loaderModel(opt) {
    switch (opt.type) {
      case 'gltf':
      case 'glb':
        if (!this.gltfloader) {
          this.gltfLoader = new GLTFLoader();
          let dracoLoader = new DRACOLoader();
          dracoLoader.setDecoderPath(this.options.path + "/");
          this.gltfLoader.load(opt.url, opt.onLoad, opt.onProgress, opt.onError);
          // this.gui.add(this.gltfLoader.position, 'x', 0, 180);
          // this.gui.add(this.gltfLoader.position, 'y', 0, 180);
          // this.gui.add(this.gltfLoader.position, 'z', 0, 180);

        }
        break;
      default:
        break;
    }
  }

  // 迭代加载
  /**
   * 
   * @param {*} objFileList 
   * @param {*} onProgress 加载进度
   * @param {*} onAllLoad 加载完成的回调
   */
  iterateLoad(objFileList, onProgress, onAllLoad) {
    let fileIndex = 0;
    let that = this;
    function iterateLoadForIt() {
      that.loaderModel({
        type: objFileList[fileIndex].type,
        url: objFileList[fileIndex].url,
        onLoad: function (obj) {
          that.model = obj;


          that.skeletonHelper = new THREE.SkeletonHelper(obj.scene)
          that.modelAnimation = obj.animations || []
          // 如果当前模型有动画则默认播放第一条动画
          if (that.modelAnimation.length) {
            const animationName = that.modelAnimation[0].name
            const config = {
              animations: that.modelAnimation,
              timeScale: 1, // 播放速度
              weight: 1, // 动作幅度
              loop: "LoopRepeat",
              animationName // 播放的动画名
            }
            that.onStartModelAnimaion(config)
          }




          console.log('----' + obj)
          // 判断是否传和传的第几个模型 加载对应的模型
          if (objFileList[fileIndex].onLoad) {
            objFileList[fileIndex].onLoad(obj)
          }
          fileIndex++;
          if (fileIndex < objFileList.length) {
            iterateLoadForIt();
          } else {
            if (onAllLoad) {
              onAllLoad();
            }
          }
        },
        onProgress: function (xhr) {
          // 判断是否传和传的第几个模型
          //  //console.log('-------------加载完成的百分比' + (xhr.loaded / xhr.total * 100))

          let modelLoadProgress = (xhr.loaded / xhr.total * 100).toFixed(2);
          //  that.jindu_text.innerText = modelLoadProgress + '%';
          const percent = xhr.loaded / xhr.total;
          // //console.log('加载进度' + percent);
         // that.percentDiv.style.width = percent * 400 + "px"; //进度条元素长度
         // that.percentDiv.style.textIndent = percent * 400 + 5 + "px"; //缩进元素中的首行文本
          // Math.floor:小数加载进度取整
         // that.percentDiv.innerHTML = Math.floor(percent * 100) + '%'; //进度百分比
         that.percentDiv.getElementsByClassName('el-progress__text')[0].innerHTML=Math.floor(percent * 100) + '%';

          that.percentDiv.getElementsByClassName('el-progress-bar__inner')[0].style.width=Math.floor(percent * 100) + '%';;


          // //console.log((xhr.loaded / xhr.total * 100).toFixed(2) > 99)

          // if ((xhr.loaded / xhr.total * 100).toFixed(2) < 100) {
          // showLoading('模型加载中...');

          // }else{
          //   hideLoading();

          // }
          if (objFileList[fileIndex].onProgress) {
            objFileList[fileIndex].onProgress(xhr, fileIndex);
          }
          if (onProgress) {
            onProgress(xhr, fileIndex);
          }
        },
        onError: function (err) {
          console.error(err)
        }
      })
    }

    iterateLoadForIt()
  }
  test(val) {
    return val
  };
  // 开始执行动画
  onStartModelAnimaion(config) {
    this.onSetModelAnimaion(config)
    cancelAnimationFrame(this.animationFram)
    this.animationFrameFun()
  }
  // 设置模型动画
  onSetModelAnimaion({ animations, animationName, loop, timeScale, weight }) {
    this.animationMixer = new THREE.AnimationMixer(this.model.scene)
    const clip = THREE.AnimationClip.findByName(animations, animationName)
    if (clip) {
      this.animateClipAction = this.animationMixer.clipAction(clip)
      this.animateClipAction.setEffectiveTimeScale(timeScale)
      this.animateClipAction.setEffectiveWeight(weight)
      this.animateClipAction.setLoop(this.loopMap[loop])
      this.animateClipAction.play()
    }
  }
  // 动画帧
  animationFrameFun() {
    this.animationFram = requestAnimationFrame(() => this.animationFrameFun())
    if (this.animationMixer) {
      this.animationMixer.update(this.animationColock.getDelta())
    }
  }




  // 渲染
  render(callback) {
    callback();
    // 更新每一帧
    this.frameId = requestAnimationFrame(() => this.render(callback));
  }

  // 初始化场景
  createScene() {
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.TextureLoader().load(this.options.background);
    this.textureLoader = new THREE.TextureLoader();
  }
  // 初始化相机
  createCamera() {
    this.camera = new THREE.PerspectiveCamera(75, this.width / this.height, 0.1, 1000);
    this.camera.position.set(this.options.cx, this.options.cy, this.options.cz);


    // 相机中心点原点
    this.camera.lookAt(0, 0, 0);
  }
  // 初始化渲染器
  createRenderer() {
    this.renderer = new THREE.WebGLRenderer({
      alpha: true, // 渲染器透明
      antialias: true, // 抗锯齿
      precision: 'highp' // 着色器开启高精度
    });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(this.width, this.height)
  }

  // 创建 GUI 方便调试
  createGui() {
    this.gui = new GUI();
    this.gui.domElement.style.display = 'none';//隐藏调试窗口
    // this.gui.domElement.style.top = '20px';
    // this.gui.domElement.style.right = '600px';
    // this.gui.domElement.style.width = '300px';
    //创建一个对象，对象属性的值可以被GUI库创建的交互界面改变
    // const obj = {
    //   x: 30,
    // };
    // // gui增加交互界面，用来改变obj对应属性
    // this.gui.add(obj, 'x', 0, 100);

  }

  resizeWin() {
    window.addEventListener("resize", () => {
      this.camera.aspect = this.width / this.height;
      // 更新相机矩阵
      this.camera.updateProjectionMatrix();
      // 更新渲染器大小
      this.renderer.setSize(this.width, this.height);
      // 更新文本渲染器
      if (this.cssRenderer) {
        this.cssRenderer.setSize(this.width, this.height);
      }
    })
  }
  onMouseClick(event, clickMod) {
    let raycaster = new THREE.Raycaster();
    let mouse = new THREE.Vector2();
    let div3D = this.el;
    // let div3D = document.getElementById('container');
    //console.log(clickMod)
    // 通过鼠标点击的位置计算出raycaster所需要的点的位置，以屏幕中心为原点，值的范围为-1到1.
    let div3DLeft = div3D.getBoundingClientRect().left;
    let div3DTop = div3D.getBoundingClientRect().top;
    mouse.x = (event.clientX - div3DLeft) / div3D.clientWidth * 2 - 1;
    mouse.y = -((event.clientY - div3DTop) / div3D.clientHeight) * 2 + 1;
    // 通过鼠标点的位置和当前相机的矩阵计算出raycaster
    raycaster.setFromCamera(mouse, this.camera);
    // 获取raycaster直线和所有模型相交的数组集合
    this.raycastMeshes(clickMod, raycaster);
  }

  raycastMeshes(callback, raycaster) {
    let intersects = [];
    // 获取整个场景
    let theScene = this.scene || new THREE.Scene();
    // 获取鼠标点击点的射线
    let theRaycaster = raycaster || new THREE.Raycaster();
    // 对场景及其子节点遍历
    for (let i in theScene.children) {
      // 如果场景的子节点是Group或者Scene对象
      if (
        theScene.children[i] instanceof THREE.Group ||
        theScene.children[i] instanceof THREE.Scene
      ) {
        // 场景子节点及其后代，被射线穿过的模型的数组集合
        // intersects = theRaycaster.intersectObjects(theScene.children[i].children, true)
        let rayArr = theRaycaster.intersectObjects(
          theScene.children[i].children,
          true
        );
        intersects.push(...rayArr);
      } else if (theScene.children[i] instanceof THREE.Mesh) {
        // 如果场景的子节点是Mesh网格对象，场景子节点被射线穿过的模型的数组集合
        // intersects.push(theRaycaster.intersectObject(theScene.children[i]))
      }
    }
    intersects = this.filtersVisibleFalse(intersects); // 过滤掉不可见的
    // 被射线穿过的模型的数组集合
    if (intersects && intersects.length > 0) {
      return callback(intersects);
    }
    // this.hiddenDetailDiv()
    return null;
  }
  filtersVisibleFalse(arr) {
    let arrList = arr;
    if (arr && arr.length > 0) {
      arrList = [];
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].object.visible) {
          arrList.push(arr[i]);
        }
      }
    }
    return arrList;
  }
  clearnModel() {
    // let scene = this.scene;
    if (this.scene !== null && this.scene.children.length > 3) {
      this.scene.children = []
      // composer.removePass(outlinePass);
      // 必须要清空当前div下的canvas不然canvas会继续叠加

      if (this.el !== null) {
        this.el.removeChild(this.el.firstChild)
      }

    }
  }
  //初始化模型位置
  resetControl() {
    this.control.reset();
    ////console.log(111);
    this.model.scene.scale.set(1, 1, 1)
  }
  //旋转控制器
  horizontalRotationControl(num) {
    //console.log(this.control)
    this.control.setAngle(this.control.getPolarAngle(), this.control.getAzimuthalAngle() + num, this.control.getDistance())
  }
  //场景缩放
  zoomController(num) {
    this.model.scene.scale.set(this.model.scene.scale.x + num, this.model.scene.scale.y + num, this.model.scene.scale.z + num)
  }
  //模型材质修改,val需高亮的模型name;
  changeMaterial(val) {
    const texLoader = new THREE.TextureLoader();
    const texture = texLoader.load('https://res-more.deebike.com/models/蓝.png');// 加载手机mesh另一个颜色贴图
    //texture.encoding = THREE.sRGBEncoding; //和渲染器.outputEncoding一样值

    this.model.scene.traverse(function (obj) {
      if (obj.isMesh && obj.name.includes(val)) {
        console.log(obj)
        // obj.visible=false;//控制模型内元素显隐藏
        // // 重新设置材质
        obj.material = new THREE.MeshLambertMaterial({
          map: texture,
          roughness: 0
        });
        //obj.material.map = texture;

      }
    });
  }
  //控制模型动画开始
  startAction() {
    // animationMixer
    const IdleAction = this.animationMixer.clipAction(this.model.animations[0]);
    IdleAction.play();
  }
  //控制模型动画暂停
  stopAction() {
    // animationMixer
    const IdleAction = this.animationMixer.clipAction(this.model.animations[0]);
    IdleAction.stop();
  }
  //控制模型动画暂停
  addspeed() {
    // animationMixer
    const IdleAction = this.animationMixer.clipAction(this.model.animations[0]);
    // IdleAction.stop();
    console.log(IdleAction);
    IdleAction.timeScale = IdleAction.timeScale + 1;
  }
}
