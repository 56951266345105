<template>
  <div class="v-card">
    <div class="vc-list">
      <p>温度</p>
      <p>{{ screenData.temprature }}</p>
    </div>
    <div class="vc-list">
      <p>湿度</p>
      <p>{{screenData.humidity}}</p>
    </div>
    <div class="vc-list">
      <p>CO2</p>
      <p>{{screenData.co2}}</p>
    </div>
    <div class="vc-list">
      <p>PM2.5</p>
      <p>{{screenData.pm25}}</p>
    </div>
    <div class="vc-list">
      <p>TVOC</p>
      <p>{{screenData.tvoc}}</p>
    </div>
  </div>
</template>

<script lang="js">
import { mapState } from 'vuex';
export default {
  name: 'VCard4',
  data() {
    return {
      screenData: {}
    }
  },
  props: { // 切换项目，获取天气
    propsData: {
      type: String, 
      default: ""
    }
  },
  watch: {
    propsData: {
      handler() {
        this.projectCode = this.propsData;
        this.initData()
      }
    }
  },
  computed: {
    ...mapState(["projectCurrentRowData"])
  },
  mounted() {
    const currentData = this.projectCurrentRowData || {};
    this.projectCode = currentData.projectCode;
    this.initData()
  },
  methods: {
    initData() {
      this.request.screenWeather({ project: this.projectCode }).then(res => {
        if (res.code == 0) {
          this.screenData = Object.assign({}, res.data);
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.v-card {
  height: 82px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px 14px;
  border-radius: 1px;
  opacity: 1;
  background: linear-gradient(180deg, rgba(2, 255, 255, 0.22) 0%, rgba(117, 255, 241, 0.06) 100%);
  box-sizing: border-box;
  border: 1px solid;
  box-sizing: border-box;
  border-image: linear-gradient(119deg, rgba(0, 255, 200, 0.13) 40%, rgba(207, 236, 255, 0) 70%, rgba(184, 228, 255, 0.32) 112%) ;
  >.vc-list {
   
    >p {
      text-align: center;
      line-height: 24px;
    }
    >p:nth-child(1) {
      font-family: "microsoft yahei ui","microsoft yahei";
      font-size: 12px;
      letter-spacing: 0em;
      color: rgba(255, 255, 255, 0.6);
    }
    >p:nth-child(2) {
      font-family: "microsoft yahei ui","microsoft yahei";
      font-size: 14px;
      letter-spacing: 0em;
      color: #FFFFFF;
      font-weight: 700;
    }
  }
}
</style>
