<template>
  <v-card-bg>
    <div class="v-card">

    
    <div class="vc-title">
      <span><v-title-bg>年度总能耗</v-title-bg></span>
      <span class="btn">查看趋势</span>
    </div>

    <div class="vc-progress">
      <p class="count">
        <span class="num">{{screenData.year || 0}}</span>
        <span :class="['rate', setIcon(screenData.rate)]">{{Math.abs(screenData.rate||0)}}%</span>
      </p>
      <!-- <div class="progress-box">
        <div class="progress_1" :style="{ width: '20%'}"></div>
        <div class="progress_2" :style="{ width: '35%'}"></div>
        <div class="progress_3" :style="{ width: '42%'}"></div>
      </div> -->
    </div>

    <div class="vc-list">
      <span class="title">分项</span>
      <p v-for="i,index in (screenData.energyList || [])" :key="index">
        <span class="label">{{i.name}}</span>
        <span>
          <span class="num">{{i.value}}</span>
          <span class="rate">{{setProgress(i.value)}}%</span>
        </span>
      </p>
    </div>
  </div>
  </v-card-bg>
</template>

<script lang="js">

import VCardBg from "./v-card-bg.vue";
import VTitleBg from "./v-title-bg.vue";

export default {
  name: 'VCard',
  components: {
    VCardBg,
    VTitleBg
  },
  props: {
    propsData: {
      type: Object, 
      default: () => {
        return {}
      }
    }
  },
  watch: {
    propsData: {
      deep: true,
      handler() {
        this.screenData = Object.assign({}, this.propsData);
      }
    }
  },
  data() {
    return {
      screenData: {}
    }
  },
  methods: {
    setProgress(num) {
      const n = (((num*1) / this.screenData.year)*100).toFixed(2);
      return n
    },
    // 根据值的正负区分样式
    setIcon(val) {
      if (val > 0) {
        return "up";
      } else if (val < 0) {
        return "down";
      } else {
        return "";
      }
    },
  }
}
</script>
<style scoped lang="scss">
.v-card {
  height: 221.27px;
  padding: 12px;
  box-sizing: border-box;

  >.vc-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btn {
      font-family: "microsoft yahei ui","microsoft yahei";
      font-size: 12px;
      line-height: 24px;
      color: rgba(2, 255, 255, 0.58);
      cursor: pointer;
    }
  }
  >.vc-progress {
    margin-top: 12px;
    margin-bottom: 24px;
    .count {
      .num {
        font-size: 18px;
        font-weight: bold;
        font-variation-settings: "opsz" auto;
        background: linear-gradient(180deg, #FFFFFF 0%, #EDFEFF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }

      .rate {
        font-family: "microsoft yahei ui","microsoft yahei";
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0em;
        font-variation-settings: "opsz" auto;
        color: #08D680;
        position: relative;
        padding-left: 18px;
        margin-left: 54px;
        &.up {
          content: "";
          display: inline-block;
          width: 12px;
          height: 20px;
          background: url("~@/assets/images/visual/arrow_down.png") no-repeat;
          background-size: 100% 100%;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          margin: auto;
        }
        &.up {
          &::after {
            content: "";
            display: inline-block;
            width: 18px;
            height: 16px;
            background: url("~@/assets/images/visual/arrow_up.png") no-repeat;
            background-size: 100% 100%;
            margin-left: 8px;
          }
        }

        &.down {
          &::after {
            content: "";
            display: inline-block;
            width: 18px;
            height: 16px;
            background: url("~@/assets/images/visual/arrow_down.png") no-repeat;
            background-size: 100% 100%;
            margin-left: 8px;
          }
        }
      }
    }

    .progress-box {
      width: 100%;
      height: 5px;
      margin: 12px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .progress_1 {
        height: 100%;
        background: #02FFFF;
      }

      .progress_2 {
        height: 5px;
        background: #004CFF;
        margin: 0 4px;
      }

      .progress_3 {
        height: 100%;
        background: #FF8900;
      }
    }
  }

  >.vc-list {
    .title {
      font-family: "microsoft yahei ui","microsoft yahei";
      font-size: 12px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;

      font-variation-settings: "opsz" auto;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(237, 254, 255, 0.4) 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    p {
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 28px;
      .label {
        font-family: "microsoft yahei ui","microsoft yahei";
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0em;

        font-variation-settings: "opsz" auto;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.9) 0%, rgba(237, 254, 255, 0.9) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        position: relative;
        padding-left: 16px;

        &::before {
          content: "";
          display: inline-block;
          width: 10px;
          height: 10px;
          background-color: #fff;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          margin: auto;
        }
      }

      .num {
        font-family: DIN Alternate;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0em;

        font-variation-settings: "opsz" auto;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.9) 0%, rgba(237, 254, 255, 0.9) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        margin-right: 12px;
      }

      .rate {
        font-family: "microsoft yahei ui","microsoft yahei";
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0em;

        font-variation-settings: "opsz" auto;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(237, 254, 255, 0.5) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }

      &:nth-child(2) {
        .label::before {
          background-color: rgba(2, 255, 255, 1);
        }
      }

      &:nth-child(3) {
        .label::before {
          background-color: rgba(0, 76, 255, 1);
        }
      }

      &:nth-child(4) {
        .label::before {
          background-color: rgba(255, 137, 0, 1);
        }
      }
    }
  }
}
</style>
