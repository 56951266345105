<template>
  <div class="v-opt-dialog">
    <el-button
    :type="i.id == btnId ? 'primary' : ''"
    v-for="i in nodeList"
    :key="i.id"
    @click="changeFloor(i)"
    >{{ i.name }}</el-button
    >
  </div>
</template>

<script>
export default {
  name: "VOptDialog",
  props: {
    propsData: {
      type: Object, 
      default: () => {
        return {}
      }
    }
  },
  watch: {
    propsData: {
      deep: true,
      handler() {
        const screenData = Object.assign({}, this.propsData);
        this.nodeList = screenData.nodeList || [];
        if (this.nodeList.length > 0) {
          this.btnId = this.nodeList[0].id;
        } else {
          this.btnId = null;
        }
        console.log("nodeList---", this.btnId)
      }
    }
  },
  data() {
    return {
      btnId: 0,
      list: [
        {
          id: 0,
          label: "全部",
        },
        {
          id: 3,
          label: "3F",
        },
        {
          id: 2,
          label: "2F",
        },
        {
          id: 1,
          label: "1F",
        },
        {
          id: -1,
          label: "-1F",
        },
      ],
      nodeList: []
    };
  },
  methods: {
    changeFloor(e) {
      this.btnId = e.id;
      this.$emit("changeFloor", e.id)
    }
  }
};
</script>

<style scoped lang="scss">
.v-opt-dialog {
  width: 64px;
  height: 264px;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 1px;
  color: #fff;
  overflow-y: auto;
  /*滚动条整体样式,高宽分别对应横竖滚动条的尺寸*/
  &::-webkit-scrollbar {
      display: none;
  }

  /*滚动条里面小方块*/
  &::-webkit-scrollbar-thumb {
    display: none;
  }

  /*滚动条里面轨道*/
  &::-webkit-scrollbar-track {
    display: none;
  }
  .el-button {
    width: 100%;
    margin: 0;
    border: none;
    margin-bottom: 10px;
    font-size: 14px;
    transition: all .2s linear;
    text-align: center;
    padding: 0;
    height: 32px;
    line-height: 32px;
    &:last-child {
      margin-bottom: 0;
    }
    &.el-button--primary {
      background: url("~@/assets/images/visual/btn_bg.png") no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>
