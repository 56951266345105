<template>
  <div class="mr-2d-left-device">
    <img src="./images/bg.jpg" alt="">
    <!-- 光储模式 -->
    <div class="taiyangIcon" v-if="gcModeValue === 'qingtian'">
      <img src="./images/taiyang.png" alt="">
    </div>
    <div class="taiyangYinIcon" v-if="gcModeValue === 'yintian'">
      <img src="./images/taiyang-yin.png" alt="">
    </div>
    <div class="yueliangIcon" v-if="gcModeValue === 'yewan'">
      <img src="./images/yueliang.png" alt="">
    </div>
    <!-- 空调模式 -->
    <div class="kongtiao-mode">
      <img src="./images/gif/kongtiao-zhire.gif" alt="" v-if="setKongtiao === 'zhire'">
      <img src="./images/gif/kongtiao-zhileng.gif" alt="" v-else-if="setKongtiao === 'zhileng'">
      <img src="./images/kongtiao-lixian.png" alt="" v-else>
    </div>
    <!-- 电表 -->
    <div class="dianbiao-mode">
      <img src="./images/dianbiao-gongdian.png" alt="" v-if="setDianbiao === 'gongdian'">
      <img src="./images/dianbiao-yongdian.png" alt="" v-else-if="setDianbiao === 'yongdian'">
      <img src="./images/dianbiao-lixian.png" alt="" v-else>
    </div>
    <!-- 中控 -->
    <div class="zhongkong-mode">
      <img src="./images/zhongkong-gongdian.png" alt="" v-if="setZhongkong === 'gongdian'">
      <img src="./images/zhongkong-yongdian.png" alt="" v-if="setZhongkong === 'yongdian'">
      <img src="./images/zhongkong-lixian.png" alt="" v-else>
    </div>
    <!-- 相变储能仓 -->
    <div class="cang-mode">
      <!-- <img src="./images/cang-lixian.png" alt=""> -->
      <!-- <img src="./images/cang-zhire.png" alt=""> -->
      <img src="./images/cang-zhileng.png" alt="">
    </div>
    <!-- 户用储能 -->
    <div class="user-energy" v-if="gcModeValue === 'chongdian'">
      <div class="energy-div ed1">
        <div class="energy chongdian-zhong"></div>
        <div class="energy-dot">
          <img src="./images/chongdian-di.png" alt="">
          <!-- <img src="./images/chongdian-man.png" alt=""> -->
          <!-- <img src="./images/chongdian-zhong.png" alt=""> -->
        </div>
      </div>
      <div class="energy-div ed2">
        <div class="energy chongdian-di"></div>
        <div class="energy-dot">
          <!-- <img src="./images/chongdian-di.png" alt=""> -->
          <img src="./images/chongdian-man.png" alt="">
          <!-- <img src="./images/chongdian-zhong.png" alt=""> -->
        </div>
      </div>
      <div class="energy-div ed3">
        <div class="energy chongdian-man"></div>
        <div class="energy-dot">
          <!-- <img src="./images/chongdian-di.png" alt=""> -->
          <!-- <img src="./images/chongdian-man.png" alt=""> -->
          <img src="./images/chongdian-zhong.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Mr2dLeftDevice",
  props: {
    // 总开关
    zongkaiguanMode: {
      type: String,
      default: ""
    },
    // 光储模式
    gcMode: {
      type: String,
      default: ""
    },
    // 空调模式
    kongtiaoMode: {
      type: String,
      default: ""
    },
    // 电表模式
    dianbiaoMode: {
      type: String,
      default: ""
    },
    // 运行模式
    yunxingMode: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      gcModeValue: "", // 光储模式
      kongtiaoModeValue: "", // 空调模式
      dianbiaoModeValue: "", // 电表模式
    }
  },
  watch: {
    gcMode: {
      handler(val) {
        this.gcModeValue = val;
      }
    },
    kongtiaoMode: {
      handler(val) {
        this.kongtiaoModeValue = val;
        if (val == 'zhileng') {
          this.$parent.guandaoStatus = true;

          this.$parent.changeBgimgzhileng();
        } else if (val == 'zhire') {
          this.$parent.guandaoStatus = true;

          this.$parent.changeBgimgzhire();
        } else {
          this.$parent.guandaoStatus = false;

        }
      }
    },
    dianbiaoMode: {
      handler(val) {
        console.log("电表模式-=---", val)
        this.dianbiaoModeValue = val;
      }
    }
  },
  computed: {
    // 设置空调状态
    setKongtiao() {

      if (this.zongkaiguanMode === "kaiji") {
        if (this.gcModeValue === "liwang") {
          return false
        }
        return this.kongtiaoModeValue
      }
      return false
    },
    // 设置电表状态
    setDianbiao() {
      if (this.zongkaiguanMode === "kaiji") {
        if (this.gcModeValue === "liwang") {
          return false
        }
        return this.dianbiaoModeValue
      }
      return false
    },
    // 设置中控状态
    setZhongkong() {
      return false
    }
  }
}
</script>

<style scoped lang="scss">
.mr-2d-left-device {
  width: 100%;
  height: 100%;
  position: absolute;
  transform: scale(0.9);

  .taiyangIcon {
    width: 48px;
    height: 48px;
    position: absolute;
    left: 16%;
    top: 34%;
    margin: auto;
  }

  .yueliangIcon {
    width: 38px;
    height: 38px;
    position: absolute;
    left: 16%;
    top: 34%;
    margin: auto;
  }

  .taiyangYinIcon {
    width: 63px;
    height: 32px;
    position: absolute;
    left: 16%;
    top: 34%;
    margin: auto;
  }


  .kongtiao-mode {
    width: 100px;
    height: 145px;
    position: absolute;
    left: 76%;
    right: 0;
    top: 35%;
    bottom: 0;
  }

  .dianbiao-mode {
    width: 23px;
    height: 27px;
    position: absolute;
    left: 11px;
    right: 0;
    top: 21.5%;
    margin: auto;
  }

  .zhongkong-mode {
    width: 109px;
    height: 70px;
    position: absolute;
    left: 0;
    right: 0;
    top: -16%;
    bottom: 0;
    margin: auto;
  }


  .cang-mode {
    width: 53px;
    height: 153px;
    position: absolute;
    right: -19%;
    top: -16%;
    bottom: 0;
    margin: auto;
  }

  .user-energy {
    width: 22px;
    height: 80px;
    position: absolute;
    left: 6px;
    right: 0;
    top: 38%;
    bottom: 0;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .energy-div {
      width: 100%;
      height: 90%;
      background: url("./images/chongdian-lixian.png") no-repeat;
      background-size: 100% 100%;
      position: absolute;
      bottom: 8px;
      left: 0;
      right: 0;
      margin: auto;

      &.ed1 {
        left: -58px;
      }

      &.ed3 {
        left: 28px;
      }

      .energy {
        animation: hMove 1.2s linear infinite;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;

        &.chongdian-zhong {
          background: linear-gradient(180deg, #FCC737 4%, rgba(246, 148, 44, 0.98) 96%);
        }

        &.chongdian-man {
          background: linear-gradient(180deg, #37FC9D 4%, rgba(44, 219, 246, 0.98) 100%);
        }

        &.chongdian-di {
          background: #E44D1B;
        }
      }

      .energy-dot {
        position: absolute;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        left: 4px;
        top: -7px;
        margin: auto;

        img {
          vertical-align: top;
        }
      }
    }
  }


  img {
    width: 100%;
    height: 100%;
  }

}

@keyframes hMove {
  0% {
    height: 0;
  }

  100% {
    height: 100%;
  }
}
</style>