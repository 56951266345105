<template>
  <div class="v-card">
    <v-arrow-bg :showHeader="false">
      <div class="v-card-container">
        <div class="count">
          <div class="value img">{{ screenData.coefficient || 0 }}</div>
          <div class="label">综合能耗系数</div>
        </div>
        <div class="count">
          <div class="value">{{ screenData.system || 0 }}</div>
          <div class="label">系统</div>
        </div>
        <div class="count">
          <div class="value">{{ screenData.device || 0 }}</div>
          <div class="label">设备</div>
        </div>
      </div>
    </v-arrow-bg>
  </div>
</template>

<script lang="js">
import VArrowBg from "../v-arrow-bg.vue";
export default {
  name: 'VLeftCard1',
  components: {
    VArrowBg
  },
  props: {
    propsData: {
      type: Object, 
      default: () => {
        return {}
      }
    }
  },
  watch: {
    propsData: {
      deep: true,
      handler() {
        this.screenData = Object.assign({}, this.propsData);
      }
    }
  },
  data() {
    return {
      screenData: {}
    }
  },
  methods: {
    
  }
}
</script>
<style scoped lang="scss">
.v-card  {
  width: 100%;
  height: 126px;
  border-radius: 2px;
  .v-card-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .label {
      font-family: "microsoft yahei ui","microsoft yahei";
      font-size: 12px;
      line-height: 1;
      text-align: center;
      background: linear-gradient(180deg, rgba(228, 254, 255, 0.7) 0%, rgba(237, 254, 255, 0.7) 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    .value {
      width: 63px;
      height: 54.31px;
      font-family: "microsoft yahei ui","microsoft yahei";
      font-size: 18px;
      font-weight: 500;
      color: #D9FDEE;
      line-height: 54.31px;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .img {
      background: url('../../images/left/card1-img.png') no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>
