<!--  -->
<template>
  <div class='v-2dpage'>
    <!-- <h1>2D页面</h1>
    <button @click="aa()">111</button> -->
    <div class="left-device">
      <left-device :zongkaiguanMode="zongkaiguanMode" :gcMode="gcMode" :kongtiaoMode="kongtiaoMode"
        :yunxingMode="yunxingMode"></left-device>
    </div>
    <div class="svg-gif-chufeng">
      <img v-if="guandaoStatus" src="../components/2dpage/component/images/gif/chufeng.gif" alt="">
      <img v-if="!guandaoStatus" src="../components/2dpage/component/images/chufeng.png" alt="">

    </div>
    <div class="svg-gif-jinfeng">
      <img v-if="guandaoStatus" src="../components/2dpage/component/images/gif/jinfeng.gif" alt="">
      <img v-if="!guandaoStatus" src="../components/2dpage/component/images/jinfeng.png" alt="">

    </div>
    <svg width="1344px" height="803px" :style="{ backgroundImage: `url(${imgUrl})` }">
      <g v-if="guandaoStatus">
        <linearGradient id="gcircle" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" :stop-color="gcircle" />
        </linearGradient>
        <linearGradient id="ycircle" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" :stop-color="ycircle" />
        </linearGradient>
        <linearGradient id="redlinear" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" :stop-color="redline" />
        </linearGradient>
        <linearGradient id="bluelinear" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" :stop-color="blueline" />
        </linearGradient>
        <!-- <path id="svg_476" class="ycircle"
        d="m658.51851,73.48147c0,0 58.51852,0 57.77779,-0.74073c0.74073,0.74073 7.4074,6.66665 7.4074,6.66665"
        opacity="0.5" fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" /> -->

        <path id="svg_476" class="ycircle"
          d="m659.64706,75.85294c0,0 64.70588,1.17647 63.88235,0.85294c0.82353,0.32353 2,28.55883 1.17647,28.23529c0.82353,0.32353 566.70589,2.67647 565.88235,2.35294"
          stroke-width="1.5" stroke="#000" fill="#fff" />
        <path id="svg_477" class="ycircle"
          d="m1290.57148,115.78569c0,0 0,259.04763 0,259.04763c0,0 -19.04762,0.95238 -19.04762,0.95238"
          stroke-width="1.5" stroke="#000" fill="#fff" />
        <path id="svg_478" class="ycircle"
          d="m590.57145,50.52521c0,0 12.38095,0.95238 12.28569,0.40479c0.09526,0.5476 10.57146,25.3095 10.47619,24.7619c0.09527,0.5476 42.95241,-1.35717 42.85714,-1.90476"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />


        <path id="svg_479" class="gcircle" d="m817.2,25c0,0 522.4,0 522,0c0.4,0 -0.4,508 0.4,508" fill-opacity="null"
          stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />
        <path id="svg_480" class="gcircle" d="m816.4,32.8c0,0 504,1.6 503.6,1.6c0.4,0 0.4,483.2 0,483.2"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />
        <path id="svg_481" class="gcircle" d="m819.2,43.6c0,0 484.8,2.4 484.4,2.4c0.4,0 -1.2,440.8 -1.2,440.8"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />


        <path id="svg_482" class="gcircle" d="m1339.6,547.6c0,0 -114.4,2.4 -114.8,2.4c0.4,0 0.4,19.2 0,19.2"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />
        <path id="svg_483" class="gcircle" d="m1320.4,532.4c0,0 -308,-1.6 -307.2,-1.6c0.8,0 -0.8,38.4 -1.2,38.4"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />
        <path id="svg_483" class="gcircle" d="m1302,497.8c0,-1.6 -479.2,0 -478.4,0c0.8,0 0,70.4 -0.4,70.4"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />
        <path id="svg_484" class="blueline" d="m605.11625,510.30234c0,0 521.86048,1.39535 521.39537,1.39533"
          opacity="0.5" fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />
        <path id="svg_485" class="blueline"
          d="m604.65114,406.02327c0,0 0,-193.02326 -0.46509,-193.02327c0.46509,0.00001 6.97672,-6.97674 6.51163,-6.97674c0.46509,0.00001 515.81394,0.46512 515.34884,0.46512"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />

        <path id="svg_486" class="blueline" d="m569.6,412.8c0,0 35.6,0.4 35.6,0.4c0,0 0,95.6 0,95.6" fill-opacity="null"
          stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />
        <path id="svg_487" class="redline"
          d="m569.71429,385.71429c0,0 48,-0.57143 48,-0.57143c0,0 0,135.42857 0,135.42857c0,0 516.57143,0 516.57143,0"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />
        <path id="svg_488" class="redline"
          d="m617.21429,380.71429c0,0 0.57143,-154.85714 0.57143,-155.42857c0,0.57143 7.42857,-6.85714 7.42857,-6.85714c0,0 508,-0.57143 507.42857,-1.14286"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />
        <path id="svg_489" class="redline"
          d="m848.48483,379.8182c0,0 -77.57576,0 -78.1818,-0.00001c0.60603,0.00001 -5.45457,-6.66665 -6.06061,-6.66667c0.60603,0.00001 12.72725,-13.33332 12.12121,-13.33333c0.60603,0.00001 64.2424,-0.60605 63.63636,-0.60606"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />
        <path id="svg_490" class="redline"
          d="m844.48483,685.8182c0,0 -77.57576,0 -78.1818,-0.00001c0.60603,0.00001 -5.45457,-6.66665 -6.06061,-6.66667c0.60603,0.00001 12.72725,-13.33332 12.12121,-13.33333c0.60603,0.00001 64.2424,-0.60605 63.63636,-0.60606"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />

        <path id="svg_491" class="redline"
          d="m696.66667,344.66667l-1.33333,73.33333l10,4.66667l206,0.66667l5.33333,-8.66667l-1.33333,-61.33333l8,-8l140,0.66667"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />
        <path id="svg_492" class="redline"
          d="m696.46667,650.06667l-1.33333,73.33333l10,4.66667l206,0.66667l5.33333,-8.66667l-1.33333,-61.33333l8,-8l140,0.66667"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />
        <path id="svg_493" class="blueline"
          d="m685.80647,379.35483l-0.6452,46.45161l5.16129,6.45161l227.74193,-1.29032l4.51613,-7.74194l-0.64516,-61.29032l5.16129,-7.74194l116.12903,-0.64516"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />
        <path id="svg_495" class="blueline" d="m605.1613,370.61289c0,0 23.22581,0.64516 23.22577,0.64516" opacity="0.5"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />
        <path id="svg_494" class="redline" d="m617.41937,337.99999c0.64516,-0.64516 9.03226,0 9.03222,0" opacity="0.5"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />

        <path id="svg_496" class="blueline"
          d="m687.80647,683.35483l-0.6452,46.45161l5.16129,6.45161l227.74193,-1.29032l4.51613,-7.74194l-0.64516,-61.29032l5.16129,-7.74194l116.12903,-0.64516"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />
        <path id="svg_497" class="redline"
          d="m689.9408,344.56211l-1.18341,108.87575l7.10059,5.91716c0.59169,0.5917 413.01771,-0.00002 413.01771,-0.00002c0,0 5.32544,-7.10059 5.32544,-7.10059c0,0 0,-99.40828 -0.59168,-99.99998c0.59168,0.5917 6.50884,-5.91717 6.50884,-5.91717c0,0 136.09467,0 135.503,-0.5917"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />
        <path id="svg_498" class="redline"
          d="m689.9408,650.06211l-1.18341,108.87575l7.10059,5.91716c0.59169,0.5917 413.01771,-0.00002 413.01771,-0.00002c0,0 5.32544,-7.10059 5.32544,-7.10059c0,0 0,-99.40828 -0.59168,-99.99998c0.59168,0.5917 6.50884,-5.91717 6.50884,-5.91717c0,0 136.09467,0 135.503,-0.5917"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />

        <path id="svg_499" class="blueline"
          d="m678.69819,379.28992c0,0 0,81.6568 -0.59169,81.0651c0.59169,0.5917 8.284,6.50886 7.69231,5.91716c0.59169,0.5917 428.99404,-0.00002 428.40236,-0.59172c0.59168,0.5917 6.50884,-4.73374 6.50884,-4.73374c0,0 0,-99.40828 -0.59168,-99.99998c0.59168,0.5917 7.10055,-5.91717 7.10055,-5.91717c0,0 114.79289,-0.59172 114.20122,-1.18342"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />
        <path id="svg_499" class="blueline"
          d="m679.69819,685.28992c0,0 0,81.6568 -0.59169,81.0651c0.59169,0.5917 8.284,6.50886 7.69231,5.91716c0.59169,0.5917 428.99404,-0.00002 428.40236,-0.59172c0.59168,0.5917 6.50884,-4.73374 6.50884,-4.73374c0,0 0,-99.40828 -0.59168,-99.99998c0.59168,0.5917 7.10055,-5.91717 7.10055,-5.91717c0,0 114.79289,-0.59172 114.20122,-1.18342"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />
        <path id="svg_501" class="redline"
          d="m517.15686,354.84314c0,0 -12.2549,-0.98039 -12.7451,-0.98039c0.49019,0 -3.43138,-7.35294 -3.92157,-7.35294c0.49019,0 0,-163.72549 -0.4902,-163.72549c0.49019,0 7.35294,-6.37255 6.86275,-6.37255c0.49019,0 88.23529,0 87.7451,0c0.49019,0 4.90196,-5.88235 4.41176,-5.88235c0.49019,0 0.49019,-66.17647 0,-66.17647"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />
        <path id="svg_502" class="redline" d="m467.64706,397.03922c0,0 51.96078,-0.4902 51.47059,-0.4902" opacity="0.5"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />
        <path id="svg_503" class="blueline" d="m467.64706,425.03922c0,0 51.96078,-0.4902 51.47059,-0.4902" opacity="0.5"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />
        <path id="svg_504" class="blueline"
          d="m781.46066,375.84271c0,0 -3.37079,-5.05618 -3.93257,-5.05619c0.56178,0.00001 5.05617,-3.37078 4.49438,-3.37079c0.56178,0.00001 59.55055,0.00001 58.98876,0"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />

        <path id="svg_505" class="blueline"
          d="m518.22901,340.84498c0,0 -6.15383,-5.3846 -6.15384,-5.3846c0.00001,0 0.00001,-139.99952 0,-140.76875c0.00001,0.76923 8.46152,-5.3846 8.46151,-6.15383c0.00001,0.76923 85.38434,0.76923 85.38432,0c0.00002,0.76923 5.38461,-8.46151 5.3846,-9.23074c0.00002,0.76923 0.00002,-78.46127 0,-79.2305"
          stroke-width="1.5" stroke="#000" fill="#fff" />



        <path id="svg_20" class="gcircle" d="m657.61195,38.80595c0,0 117.16418,0 117.16416,-0.74625" opacity="0.5"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />
        <path id="g_svg_2" class="gcircle"
          d="m811.12505,56.49996c0,0 396.25002,-2.5 396.12495,-2.74996c0.12506,0.24996 2.62506,233.99997 2.62506,233.99997"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />
        <path id="g_svg_3" class="gcircle"
          d="m811.90908,62.72727c0,0 198.18182,0 197.54547,0c0.63635,0 1.54545,215.45455 0.90909,215.45455"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />
        <path id="g_svg_4" class="gcircle"
          d="m818.63635,70c0,0 7.27273,4.54545 6.63638,4.54545c0.63635,0 -1.18183,190.90909 -1.81818,190.90909"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />


        <!-- 重画 -->
        <!-- <path id="g_svg_5" class="ycircle"
        d="m645.81817,76.54546c0,0 61.81818,0 61.18183,0c0.63635,0 1.54544,32.72727 0.90909,32.72727c0.63635,0 582.45454,-0.90909 581.81818,-0.90909c0.63636,0 1.54545,265.45455 0.90909,265.45455c0.63636,0.00001 -19.36364,3.63637 -20,3.63636"
        fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" /> -->
        <!-- 重画 -->




        <path id="g_svg_6" class="ycircle"
          d="m897.45454,108.18182c0,0 -2.72727,266.36364 -3.36363,266.36363c0.63635,0.00001 -17.54547,0.9091 -18.18182,0.90909"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />
        <path id="g_svg_7" class="ycircle"
          d="m1092.54545,108.18182c0,0 -0.90909,266.36364 -1.54545,266.36363c0.63636,0.00001 -18.45455,1.81819 -19.09091,1.81818"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />



        <!-- <path id="svg_8" class="line"
        d="m584.82757,385.51721l51.72413,-0.68964l1.37931,-166.20689c0.00001,0.68964 525.51724,2.06895 525.51724,2.06895c0,0 0,66.20689 0,66.20689c0,0 31.72414,0.68966 31.72412,0.00002"
        fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" /> -->
        <!-- 重画 -->
        <!-- <path id="svg_15" class="redline"
        d="m566.42858,386.42859l49.99999,0.71427l-1.42857,-160l9.28571,-7.85715l524.6381,-1.2l-1.13333,59.61271l2.92381,10.15872l25.71428,0.71429"
        fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" /> -->

        <!-- <path id="svg_16" class="blueline"
        d="m562.83583,414.92537c0,0 39.55224,0.74627 39.55223,0.00001c0.00001,0.74626 -0.74626,-198.50747 -0.74627,-199.25373c0.00001,0.74626 11.19404,-6.71643 11.19403,-7.46269c0.00001,0.74626 524.62689,1.49253 524.62687,0.74627c0.00002,0.74626 0.00002,76.11939 0,75.37313c0.00002,0.74626 6.71644,9.70148 6.71642,8.95522c0.00002,0.74626 26.8657,-0.00001 26.86567,-0.74627"
        fill-opacity="null" stroke-opacity="null" stroke-dasharray="null" stroke-width="1.5" stroke="#000"
        fill="#fff" /> -->


        <!-- 重画 -->




        <path id="svg_17" class="blueline"
          d="m733.47763,206.95521c0,0 -0.74627,95.52239 -0.74628,94.77613c0.00002,0.74626 8.20897,5.97014 8.20896,5.22388c0.00002,0.74626 20.14927,1.49253 20.14925,0.74627"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />
        <path id="svg_18" class="redline"
          d="m738.44778,219.14924c0,0 -0.74627,53.73134 -0.74628,52.98509c0.00002,0.74626 7.4627,5.22387 7.46269,4.47761c0.00002,0.74626 14.92539,0.74626 14.92537,0"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />
        <path id="svg_171" class="blueline"
          d="m930.47763,215.95521c0,0 -0.74627,95.52239 -0.74628,94.77613c0.00002,0.74626 8.20897,5.97014 8.20896,5.22388c0.00002,0.74626 20.14927,1.49253 20.14925,0.74627"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />
        <path id="svg_181" class="redline"
          d="m938.44778,228.14924c0,0 -0.74627,53.73134 -0.74628,52.98509c0.00002,0.74626 7.4627,5.22387 7.46269,4.47761c0.00002,0.74626 14.92539,0.74626 14.92537,0"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />
        <path id="svg_172" class="blueline"
          d="m1127.47763,215.95521c0,0 -0.74627,95.52239 -0.74628,94.77613c0.00002,0.74626 8.20897,5.97014 8.20896,5.22388c0.00002,0.74626 20.14927,1.49253 20.14925,0.74627"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />
        <path id="svg_182" class="redline"
          d="m1133.44778,228.14924c0,0 -0.74627,53.73134 -0.74628,52.98509c0.00002,0.74626 7.4627,5.22387 7.46269,4.47761c0.00002,0.74626 14.92539,0.74626 14.92537,0"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />








        <!-- <path id="svg_21" class="blueline"
        d="m602.47873,413.17703l0.77518,99.9997l535.36476,-0.62893l-0.14626,80.47365l8.96589,6.97672l28.68208,-0.77519"
        fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" /> -->



        <path id="svg_22" class="blueline"
          d="m604.64152,513.20754c0,0 0,154.08805 -0.00001,153.45912c0.00001,0.62893 9.43397,8.1761 9.43396,7.54717c0.00001,0.62893 32.70441,3.14465 32.7044,2.51572"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />

        <path id="svg_25" class="blueline"
          d="m691.32077,371.5849c0,0 0.62893,11.94969 0.62892,11.32076c0.00001,0.62892 6.91825,5.66037 6.91824,5.03145c0.00001,0.62892 18.86794,-0.00001 18.86792,-0.62893c0.00001,0.62892 3.14467,-7.54718 3.14465,-8.1761c0.00001,0.62892 0.62894,-20.12579 0.62893,-20.75472c0.00001,0.62892 6.91825,-5.66038 6.91824,-6.28931c0.00001,0.62892 127.67297,0.62892 127.67296,0c0.00002,0.62892 3.14467,4.40251 3.14465,3.77358c0.00002,0.62892 -13.20753,15.72326 -13.20755,15.09434c0.00002,0.62892 -62.89307,2.51572 -62.89308,1.88679"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />


        <path id="svg_30" class="redline"
          d="m698.01259,337.62263c0,0 1.25786,8.1761 1.25785,7.54718c0.00001,0.62892 166.66668,-0.00001 166.66667,-0.62893c0.00002,0.62892 5.66039,5.66037 5.66038,5.03145c0.00002,0.62892 -23.27042,30.8176 -23.27044,30.18868"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />






        <path id="svg_33" class="redline"
          d="m617.2931,522.50898c0,0 0.4902,114.7057 0.00002,114.70569c0.49018,0.00001 6.37252,5.88235 5.88234,5.88234c0.49018,0.00001 5.88232,0.00001 5.39215,0"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />
        <path id="svg_34" class="redline"
          d="m739.01251,522.99918c0,0 -0.4902,62.2548 -0.98037,62.2548c0.49018,0.00001 8.3333,6.37255 7.84312,6.37254c0.49018,0.00001 24.50975,-0.49019 24.01957,-0.4902"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />
        <path id="svg_35" class="redline"
          d="m937.25337,522.50898c0,0 -0.98039,61.76461 -1.47057,61.7646c0.49018,0.00001 7.84311,7.35294 7.35293,7.35293c0.49018,0.00001 25.49014,0.00001 24.99996,0"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />

        <path id="svg_36" class="blueline"
          d="m732.93997,511.23449c0,0 -0.4902,79.41164 -0.98037,79.41163c0.49018,0.00001 5.39213,6.86274 4.90195,6.86273c0.49018,0.00001 33.33326,0.4902 32.84309,0.4902"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />
        <path id="svg_37" class="blueline"
          d="m930.39063,511.72469c0,0 -0.4902,78.92145 -0.98038,78.92144c0.49018,0.00001 8.8235,7.84313 8.33332,7.84312c0.49018,0.00001 30.88229,-0.49019 30.39211,-0.4902"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />

        <path id="svg_341" class="redline"
          d="m1135.01251,522.99918c0,0 -0.4902,62.2548 -0.98037,62.2548c0.49018,0.00001 8.3333,6.37255 7.84312,6.37254c0.49018,0.00001 24.50975,-0.49019 24.01957,-0.4902"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />

        <path id="svg_361" class="blueline"
          d="m1127.93997,511.23449c0,0 -0.4902,79.41164 -0.98037,79.41163c0.49018,0.00001 5.39213,6.86274 4.90195,6.86273c0.49018,0.00001 33.33326,0.4902 32.84309,0.4902"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />





        <!-- <path id="svg_46" class="redline"
        d="m840.03775,668.4088c0,0 -3.77358,-3.77358 -3.7736,-4.40251c0.00002,0.62892 -64.15093,1.25785 -64.15094,0.62893c0.00001,0.62892 -11.94967,12.57861 -11.94969,11.94969c0.00001,0.62892 4.40253,9.43396 4.40252,8.80503c0.00001,0.62892 80.50316,0.62892 80.50314,0"
        fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" /> -->
        <path id="svg_47" class="blueline"
          d="m690.32077,676.5849c0,0 0.62893,11.94969 0.62892,11.32076c0.00001,0.62892 6.91825,5.66037 6.91824,5.03145c0.00001,0.62892 18.86794,-0.00001 18.86792,-0.62893c0.00001,0.62892 3.14467,-7.54718 3.14465,-8.1761c0.00001,0.62892 0.62894,-20.12579 0.62893,-20.75472c0.00001,0.62892 6.91825,-5.66038 6.91824,-6.28931c0.00001,0.62892 127.67297,0.62892 127.67296,0c0.00002,0.62892 3.14467,4.40251 3.14465,3.77358c0.00002,0.62892 -13.20753,15.72326 -13.20755,15.09434c0.00002,0.62892 -62.89307,2.51572 -62.89308,1.88679"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />
        <path id="svg_48" class="blueline"
          d="m780.40253,678.84276c0,0 -5.03145,-3.14465 -5.03146,-3.77358c0.00001,0.62892 4.40253,-2.51573 4.40252,-3.14465c0.00001,0.62892 57.86165,0.62892 57.86164,0"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />
        <path id="svg_49" class="redline"
          d="m694.01259,643.62263c0,0 1.25786,8.1761 1.25785,7.54718c0.00001,0.62892 166.66668,-0.00001 166.66667,-0.62893c0.00002,0.62892 5.66039,5.66037 5.66038,5.03145c0.00002,0.62892 -23.27042,30.8176 -23.27044,30.18868"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />


        <path id="svg_50" class="redline"
          d="m1060.66493,345.05828c0,0 5.39215,3.92156 4.90197,3.92156c0.49018,0.00001 -22.0588,31.37251 -22.54898,31.3725c0.49018,0.00001 -81.37244,0.4902 -81.86262,0.4902c0.49018,0.00001 -3.43138,-8.33331 -3.92156,-8.33332c0.49018,0.00001 12.25487,-12.74507 11.76469,-12.74508"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />
        <path id="svg_51" class="redline" d="m970.46899,359.27395c0,0 65.68617,0.4902 65.19599,0.49019"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />
        <path id="svg_52" class="blueline"
          d="m1044.93946,352.41121c0,0 4.90195,4.41176 4.41177,4.41175c0.49018,0.00001 -12.74509,16.66665 -13.23527,16.66664c0.49018,0.00001 -64.2156,-0.49019 -64.70578,-0.4902c0.49018,0.00001 -2.45099,-3.43136 -2.94117,-3.43137c0.49018,0.00001 5.88233,-3.43136 5.39215,-3.43137"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />
        <path id="svg_53" class="blueline" d="m980.88075,368.62688c0,0 58.33324,-0.4902 57.84306,-0.4902"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />

        <path id="svg_54" class="redline"
          d="m1060.66493,651.05828c0,0 5.39215,3.92156 4.90197,3.92156c0.49018,0.00001 -22.0588,31.37251 -22.54898,31.3725c0.49018,0.00001 -81.37244,0.4902 -81.86262,0.4902c0.49018,0.00001 -3.43138,-8.33331 -3.92156,-8.33332c0.49018,0.00001 12.25487,-12.74507 11.76469,-12.74508"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />
        <path id="svg_55" class="redline" d="m970.46899,664.27395c0,0 65.68617,0.4902 65.19599,0.49019"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />
        <path id="svg_56" class="blueline"
          d="m1044.93946,657.41121c0,0 4.90195,4.41176 4.41177,4.41175c0.49018,0.00001 -12.74509,16.66665 -13.23527,16.66664c0.49018,0.00001 -64.2156,-0.49019 -64.70578,-0.4902c0.49018,0.00001 -2.45099,-3.43136 -2.94117,-3.43137c0.49018,0.00001 5.88233,-3.43136 5.39215,-3.43137"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />
        <path id="svg_57" class="blueline" d="m980.88075,672.62688c0,0 58.33324,-0.4902 57.84306,-0.4902"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />

        <path id="svg_58" class="redline"
          d="m1261.66493,345.05828c0,0 5.39215,3.92156 4.90197,3.92156c0.49018,0.00001 -22.0588,31.37251 -22.54898,31.3725c0.49018,0.00001 -81.37244,0.4902 -81.86262,0.4902c0.49018,0.00001 -3.43138,-8.33331 -3.92156,-8.33332c0.49018,0.00001 12.25487,-12.74507 11.76469,-12.74508"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />
        <path id="svg_59" class="redline" d="m1170.46899,359.27395c0,0 65.68617,0.4902 65.19599,0.49019"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />
        <path id="svg_60" class="blueline"
          d="m1247.93946,350.41121c0,0 4.90195,4.41176 4.41177,4.41175c0.49018,0.00001 -12.74509,16.66665 -13.23527,16.66664c0.49018,0.00001 -64.2156,-0.49019 -64.70578,-0.4902c0.49018,0.00001 -2.45099,-3.43136 -2.94117,-3.43137c0.49018,0.00001 5.88233,-3.43136 5.39215,-3.43137"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />
        <path id="svg_61" class="blueline" d="m1180.88075,364.62688c0,0 58.33324,-0.4902 57.84306,-0.4902"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />

        <path id="svg_62" class="redline"
          d="m1258.66493,651.05828c0,0 5.39215,3.92156 4.90197,3.92156c0.49018,0.00001 -22.0588,31.37251 -22.54898,31.3725c0.49018,0.00001 -81.37244,0.4902 -81.86262,0.4902c0.49018,0.00001 -3.43138,-8.33331 -3.92156,-8.33332c0.49018,0.00001 12.25487,-12.74507 11.76469,-12.74508"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />
        <path id="svg_63" class="redline" d="m1170.46899,664.27395c0,0 65.68617,0.4902 65.19599,0.49019"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />
        <path id="svg_64" class="blueline"
          d="m1240.93946,659.41121c0,0 4.90195,4.41176 4.41177,4.41175c0.49018,0.00001 -12.74509,16.66665 -13.23527,16.66664c0.49018,0.00001 -64.2156,-0.49019 -64.70578,-0.4902c0.49018,0.00001 -2.45099,-3.43136 -2.94117,-3.43137c0.49018,0.00001 5.88233,-3.43136 5.39215,-3.43137"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />
        <path id="svg_65" class="blueline" d="m1180.88075,672.62688c0,0 58.33324,-0.4902 57.84306,-0.4902"
          fill-opacity="null" stroke-opacity="null" stroke-width="1.5" stroke="#000" fill="#fff" />
      </g>



      <g class="group-line" style="display: none;">
        <path id="d_svg_lt" d="m254.50001,331.24998l-0.00001,-88.12498" fill-opacity="null" stroke-opacity="null"
          stroke-width="1.5" stroke="red" fill="#fff" />
        <path id="d_svg_ll" d="m104.375,364.37498l101.875,-1.24998" fill-opacity="null" stroke-opacity="null"
          stroke-width="1.5" stroke="red" fill="#fff" />
        <path id="d_svg_lr" d="m307.75462,364.89737l78.36721,0" fill-opacity="null" stroke-opacity="null"
          stroke-width="1.5" stroke="red" fill="#fff" />
        <path id="d_svg_lb" d="m253,396.1702l-0.00001,108.51065" fill-opacity="null" stroke-opacity="null"
          stroke-width="1.5" stroke="red" fill="#fff" />
        <ellipse ry="3.05084" rx="3.38983" id="d_svg_light" cy="363.22002" cx="244.10148" fill-opacity="null"
          stroke-opacity="null" stroke-width="1" stroke="red" fill="none" />
        <ellipse ry="3.05084" rx="3.38983" id="d_svg_light2" cy="363.22002" cx="260.10148" fill-opacity="null"
          stroke-opacity="null" stroke-width="1" stroke="red" fill="red" />
        <ellipse ry="2.05084" rx="2.38983" id="d_svg_light2" cy="508.22002" cx="262.10148" fill-opacity="null"
          stroke-opacity="null" stroke-width="1" stroke="#DC3131" fill="#DC3131" />
        <ellipse ry="2.05084" rx="2.38983" id="d_svg_light2" cy="508.22002" cx="246.10148" fill-opacity="null"
          stroke-opacity="null" stroke-width="1" stroke="#1EFFCE" fill="#1EFFCE" />
        <ellipse ry="2.05084" rx="2.38983" id="d_svg_light2" cy="508.22002" cx="232.10148" fill-opacity="null"
          stroke-opacity="null" stroke-width="1" stroke="#F7CD2F" fill="#F7CD2F" />
        <ellipse ry="2.05084" rx="2.38983" id="d_svg_light2" cy="508.22002" cx="218.10148" fill-opacity="null"
          stroke-opacity="null" stroke-width="1" stroke="#1EFFCE" fill="#1EFFCE" />
        <ellipse ry="2.05084" rx="2.38983" id="d_svg_light2" cy="508.22002" cx="274.10148" fill-opacity="null"
          stroke-opacity="null" stroke-width="1" stroke="#1EFFCE" fill="#1EFFCE" />
        <ellipse ry="2.05084" rx="2.38983" id="d_svg_light2" cy="508.22002" cx="274.10148" fill-opacity="null"
          stroke-opacity="null" stroke-width="1" stroke="#1EFFCE" fill="#1EFFCE" />
        <ellipse ry="2.05084" rx="2.38983" id="d_svg_light2" cy="508.22002" cx="289.10148" fill-opacity="null"
          stroke-opacity="null" stroke-width="1" stroke="#1EFFCE" fill="#1EFFCE" />
        <path id="svg_2" d="m239.9073,583.56213c-0.34965,0 0.6993,99.64962 0.34966,99.6496" stroke-width="1.5"
          stroke="#1EFFCE" fill="#fff" />
        <path id="svg_2" d="m282.9073,583.56213c-0.34965,0 0.6993,99.64962 0.34966,99.6496" stroke-width="1.5"
          stroke="#1EFFCE" fill="#fff" />
        <path id="d_svg_kongtiao" d="m453.25844,320.40448 l0.2247,133.66294" stroke-width="1.5" stroke="#FC9637"
          fill="#FC9637" />
        <path d="m484.20172,398.02742l52.66053,-0.36697" fill-opacity="null" stroke-opacity="null" stroke-width="1"
          stroke="#DE4E26" fill="#DE4E26" />
        <path d="m585.50445,385.55037l49.1743, 1.18349" fill-opacity="null" stroke-opacity="null" stroke-width="1.5"
          stroke="#DE4E26" fill="#DE4E26" />
        <path id="d_svg1" d="m254.50001,331.24998l-0.00001,-88.12498" fill-opacity="null" stroke-opacity="null"
          stroke-width="1.5" stroke="red" fill="#fff" />
        <path id="d_svg2" d="m104.375,364.37498l101.875,-1.24998" fill-opacity="null" stroke-opacity="null"
          stroke-width="1.5" stroke="red" fill="#fff" />
        <path id="d_svg3" d="m307.75462,364.89737l78.36721,0" fill-opacity="null" stroke-opacity="null"
          stroke-width="1.5" stroke="red" fill="#fff" />
        <path id="d_svg4" d="m253,396.1702l-0.00001,108.51065" fill-opacity="null" stroke-opacity="null"
          stroke-width="1.5" stroke="red" fill="#fff" />
        <ellipse ry="3.05084" rx="3.38983" id="d_svg5" cy="363.22002" cx="244.10148" fill-opacity="null"
          stroke-opacity="null" stroke-width="1" stroke="red" fill="none" />
        <ellipse ry="3.05084" rx="3.38983" id="d_svg6" cy="363.22002" cx="260.10148" fill-opacity="null"
          stroke-opacity="null" stroke-width="1" stroke="red" fill="red" />
        <ellipse ry="2.05084" rx="2.38983" id="d_svg7" cy="508.22002" cx="262.10148" fill-opacity="null"
          stroke-opacity="null" stroke-width="1" stroke="#DC3131" fill="#DC3131" />
        <ellipse ry="2.05084" rx="2.38983" id="d_svg8" cy="508.22002" cx="246.10148" fill-opacity="null"
          stroke-opacity="null" stroke-width="1" stroke="#1EFFCE" fill="#1EFFCE" />
        <ellipse ry="2.05084" rx="2.38983" id="d_svg9" cy="508.22002" cx="232.10148" fill-opacity="null"
          stroke-opacity="null" stroke-width="1" stroke="#F7CD2F" fill="#F7CD2F" />
        <ellipse ry="2.05084" rx="2.38983" id="d_svg10" cy="508.22002" cx="218.10148" fill-opacity="null"
          stroke-opacity="null" stroke-width="1" stroke="#1EFFCE" fill="#1EFFCE" />
        <ellipse ry="2.05084" rx="2.38983" id="d_svg11" cy="508.22002" cx="274.10148" fill-opacity="null"
          stroke-opacity="null" stroke-width="1" stroke="#1EFFCE" fill="#1EFFCE" />
        <ellipse ry="2.05084" rx="2.38983" id="d_svg12" cy="508.22002" cx="274.10148" fill-opacity="null"
          stroke-opacity="null" stroke-width="1" stroke="#1EFFCE" fill="#1EFFCE" />
        <ellipse ry="2.05084" rx="2.38983" id="d_svg13" cy="508.22002" cx="289.10148" fill-opacity="null"
          stroke-opacity="null" stroke-width="1" stroke="#1EFFCE" fill="#1EFFCE" />
        <path id="d_svg14" d="m239.9073,583.56213c-0.34965,0 0.6993,99.64962 0.34966,99.6496" stroke-width="1.5"
          stroke="#1EFFCE" fill="#fff" />
        <path id="d_svg15" d="m282.9073,583.56213c-0.34965,0 0.6993,99.64962 0.34966,99.6496" stroke-width="1.5"
          stroke="#1EFFCE" fill="#fff" />
        <path id="d_svg16" d="m453.25844,320.40448 l0.2247,133.66294" stroke-width="1.5" stroke="#FC9637"
          fill="#FC9637" />
        <path id="d_svg17" d="m484.20172,398.02742l52.66053,-0.36697" fill-opacity="null" stroke-opacity="null"
          stroke-width="1" stroke="#DE4E26" fill="#DE4E26" />
        <path id="d_svg18" d="m585.50445,385.55037l49.1743, 1.18349" fill-opacity="null" stroke-opacity="null"
          stroke-width="1.5" stroke="#000" fill="#fff" />
      </g>

    </svg>
    <!-- <button @click="setModColor('redline', '#FEC74500')">111</button> -->

    <!-- <button @click="setModDirection('redline', 'dash2')">11</button> -->

  </div>
</template>

<script>
import LeftDevice from "./2dpage/component/left-device.vue";
export default {
  components: {
    LeftDevice
  },
  props: {
    // 总开关
    zongkaiguanMode: {
      type: String,
      default: ""
    },
    // 空调模式
    kongtiaoMode: {
      type: String,
      default: ""
    },
    // 光储模式传值
    gcMode: {
      type: String,
      default: ""
    },
    // 运行模式
    yunxingMode: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      points: '10,190 10,10  190,10 190,200',
      points2: '190,10 300,11 ',
      svgs: [],
      gcircle: '#A7FF5A',//绿球
      ycircle: '#FEC745',//黄球
      redline: '#FF8C00',//红虚线
      blueline: '#23C8FF',//蓝虚线
      imgUrl: require('@/assets/images/visual/2dbg_1.png'),
      guandaoStatus: true

    };
  },
  computed: {},
  watch: {},
  //方法集合
  methods: {
    //设置颜色显隐用透明度控制
    setModColor(className, color) {
      this[className] = color;

      this.imgUrl = require('@/assets/images/visual/2dbg_2.png');
    },
    changeBgimgzhileng() {
      this.imgUrl = require('@/assets/images/visual/2dbg_2.png');
      this.redline = '#24C8FF';
      this.blueline = '#FFE82A';
    },
    changeBgimgzhire() {
      this.imgUrl = require('@/assets/images/visual/2dbg_1.png');
      this.redline = '#FF8C00';
      this.blueline = '#23C8FF';
    },
    //设置走向
    setModDirection(className, dash) {
      const elements = document.getElementsByClassName(className);
      for (var i = 0; i < elements.length; i++) {
        elements[i].style.animation = dash + ' 5000s linear infinite'
      }
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  beforeCreate() { }, //生命周期 - 创建之前
  beforeMount() { }, //生命周期 - 挂载之前
  beforeUpdate() { }, //生命周期 - 更新之前
  updated() { }, //生命周期 - 更新之后
  beforeDestroy() { }, //生命周期 - 销毁之前
  destroyed() { }, //生命周期 - 销毁完成
  activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped lang="scss">
.v-2dpage {
  height: 803px;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  // background: #000;
  transform: scale(0.95);

  .svg-gif-chufeng {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 42%;
    top: 4.5%;
    margin: auto;
    z-index: 10;
    transform: rotateY(180deg);

    img {
      width: 100%;
      height: 100%;
    }
  }

  .svg-gif-jinfeng {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 46.7%;
    top: 4.5%;
    margin: auto;
    z-index: 10;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .left-device {
    width: 37%;
    height: 90%;
    position: absolute;
    left: 1%;
    top: 6%;
    bottom: 0;
    margin: auto;
    z-index: 10;
  }

  button {
    position: absolute;
    z-index: 99;
  }

  h1 {
    color: #6e6e6e;
  }

  svg {
    // background: url("~@/assets/images/visual/2dbg_2.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  // path {
  //   fill: none;
  //   stroke-dasharray: 0, 20;
  //   stroke: url(#linear);
  //   stroke-linecap: round;
  //   stroke-width: 5;
  //   animation: dash 5000s linear infinite;
  // }
  // .round {
  //   fill: none;
  //   stroke-dasharray: 0, 20;
  //   stroke: url(#linear);
  //   stroke-linecap: round;
  //   stroke-width: 5;
  //   animation: dash 5000s linear infinite;
  // }
  .group-line {
    >path {
      fill: none;
      stroke-dasharray: 0, 20;
      stroke-linecap: round;
      stroke-width: 5;
      animation: dash 5000s linear infinite;
    }

    #d_svg_kongtiao {
      fill: red;
      stroke-dasharray: 0, 0;
      animation: none;
      stroke-width: 2;
    }
  }

  .ycircle {
    fill: none;
    stroke-dasharray: 0, 20;
    stroke: url(#ycircle);
    stroke-linecap: round;
    stroke-width: 5;
    animation: dash2 5000s linear infinite;
  }

  .gcircle {
    fill: none;
    stroke-dasharray: 0, 20;
    stroke: url(#gcircle);
    stroke-linecap: round;
    stroke-width: 5;
    animation: dash 5000s linear infinite;
  }

  .line {
    fill: none;
    stroke-dasharray: 10, 20;
    stroke: url(#linear);
    stroke-linecap: round;
    stroke-width: 2;
    animation: dash 5000s linear infinite;
  }

  .redline {
    fill: none;
    stroke-dasharray: 10, 20;
    stroke: url(#redlinear);
    stroke-linecap: round;
    stroke-width: 2;
    animation: dash 5000s linear infinite;
  }

  .blueline {
    fill: none;
    stroke-dasharray: 10, 20;
    stroke: url(#bluelinear);
    stroke-linecap: round;
    stroke-width: 2;
    animation: dash2 5000s linear infinite;
  }

  @keyframes dash {
    to {
      stroke-dashoffset: -200000;
    }
  }

  @keyframes dash2 {
    to {
      stroke-dashoffset: 200000;
    }
  }



}
</style>
<style>
@keyframes dash {
  to {
    stroke-dashoffset: -200000;
  }
}

@keyframes dash2 {
  to {
    stroke-dashoffset: 200000;
  }
}
</style>
