<template>
  <div class="v-arrow-bg">
    <i class="arrow top-left"></i>
    <i class="arrow top-right"></i>
    <i class="arrow bottom-left"></i>
    <i class="arrow bottom-right"></i>
    
    <div class="v-arrow-header" v-if="showHeader"><slot name="header"></slot></div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "VArrowBg",
  props: {
    showHeader: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped lang="scss">
.v-arrow-bg {
  width: 100%;
  height: 100%;
  border: 1px solid ;
  background: rgba(36, 37, 50, 0.8);
  border-image: linear-gradient(180deg, rgba(84, 90, 124, 0.59) 0%, rgba(109, 192, 253, 0) 3%, rgba(167, 178, 201, 0.2093) 91%, rgba(84, 90, 124, 0.57) 100%) 1;
  backdrop-filter: blur(10px);
  box-sizing: border-box;
  position: relative;
  padding: 12px;

  .v-arrow-header {
    width: 183.96px;
    height: 32px;
    line-height: 32px;
    background: url("../images/left/header-bg.png") no-repeat;
    background-size: 100% 100%;
    font-family: "microsoft yahei ui","microsoft yahei";
    font-size: 16px;
    color: #FFFFFF;
    padding-left: 24px;
  }
  .arrow {
    position: absolute;
    width: 8px;
    height: 8px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .top-left {
    background-image: url('../images/bg-arrow.png');
    left: 4px;
    top: 4px;
  }

  .top-right {
    background-image: url('../images/bg-arrow.png');
    right: 4px;
    top: 4px;
    transform: rotate(85deg);
  }

  .bottom-left {
    background-image: url('../images/bg-arrow.png');
    left: 4px;
    bottom: 4px;
    transform: rotate(-65deg);
  }

  .bottom-right {
    background-image: url('../images/bg-arrow.png');
    right: 4px;
    bottom: 4px;
    transform: rotate(180deg);
  }
}
</style>