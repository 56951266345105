<template>
  <v-card-bg>
  <div class="v-card">
    <div class="vc-title">
      <span><v-title-bg>故障报警</v-title-bg></span>
      <span class="btn">日</span>
    </div>

    <div class="vc-list">
      <div v-for="i, index in (screenData.faultList || [])" :key="index">
        <span class="label">
          <i><img :src='require("@/assets/images/visual/"+(index+1)+".png")' alt=""></i>
          {{i.name}}
        </span>
        <div class="num-box">
          <span class="num" :style="{width: setProgress(i.value) + '%'}"></span>
        </div>
        <span class="rate">{{i.value}}</span>
      </div>
    </div>
    
  </div>
</v-card-bg>
</template>

<script lang="js">
import { toFile } from "qrcode";
import VCardBg from "./v-card-bg.vue";
import VTitleBg from "./v-title-bg.vue";

export default {
  name: 'VCard',
  components: {
    VCardBg,
    VTitleBg
  },
  props: {
    propsData: {
      type: Object, 
      default: () => {
        return {}
      }
    }
  },
  watch: {
    propsData: {
      deep: true,
      handler() {
        this.screenData = Object.assign({}, this.propsData);
        const fail = this.screenData.faultList;
        this.totalNum = 0;
        for(let i=0; i<fail.length; i++) {
          this.totalNum +=fail[i].value
        }
      }
    }
  },
  data() {
    return {
      img1: require("@/assets/images/visual/1.png"),
      img2: require("@/assets/images/visual/2.png"),
      img3: require("@/assets/images/visual/3.png"),
      img4: require("@/assets/images/visual/4.png"),
      img5: require("@/assets/images/visual/5.png"),
      img6: require("@/assets/images/visual/6.png"),
      screenData: {},
      totalNum: 0
    }
  },
  methods: {
    setProgress(num) {
      const n = (num / this.totalNum).toFixed(6) * 100;
      return n
    }
  }
}
</script>
<style scoped lang="scss">
.v-card {
  height: 181.42px;
  padding: 12px;
  >.vc-title {
    position: relative;
    width: 100%;

    .btn {
      font-family: "microsoft yahei ui","microsoft yahei";
      font-size: 12px;
      font-weight: normal;
      line-height: 24px;
      letter-spacing: 0em;
      color: #7C7C7C;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      cursor: pointer;
    }
  }

  >.vc-list {

    >div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 4px;
      &:nth-child(1) {
        margin-top: 8px;
      }
      .label {
        font-family: "microsoft yahei ui","microsoft yahei";
        font-size: 12px;
        font-weight: normal;
        letter-spacing: 0em;
        font-variation-settings: "opsz" auto;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.9) 0%, rgba(237, 254, 255, 0.9) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        width: 80px;
        line-height: 1.5;
      }

      .num-box {
        width: 50%;
        height: 5px;
        background-color: rgba(255, 255, 255, .2);
        position: relative;
        .num {
          font-size: 12px;
          display: inline-block;
          height: 5px;
          width: 100%;
          background-color: #02FFFF;
          position: absolute;
          left: 0;
          top: 0;
        }
      }

      

      .rate {
        font-family: "microsoft yahei ui","microsoft yahei";
        font-size: 12px;
        font-weight: normal;
        line-height: 12px;
        text-align: right;
        letter-spacing: 0px;

        font-variation-settings: "opsz" auto;
        color: rgba(255, 255, 255, 0.4);
      }
     
    }
  }
}
</style>
