<template>
    <div class="mr-visual">

        <div id="container" @click="onMouseClick" class="container">

        </div>
        <div id="container2" class="container">
            <!-- <div id="per"> </div> -->
            <el-progress id="per2"  :percentage="0"></el-progress>

        </div>
        <!-- <p id="per" @click="app.resetControl()">111</p> -->
        <div v-if="infoBox">
            <div class="popinfos" :style="{ left: tranLeft, top: tranTop }">
                <div class="title">{{ infos.name }}</div>
                <div class="content">

                    <p><span class="label">运行状态</span><span class="val">{{ infos.id }}</span></p>
                    <p> <span class="label">当前温度</span><span class="val">{{ infos.name }}</span><span
                            class="dw">°C</span></p>
                    <p> <span class="label">运行时间</span><span class="val" :title="infos.uuid">{{ infos.uuid
                            }}</span><span class="dw">小时</span></p>
                    <p><span class="label">最近故障报警</span><span class="val">{{ infos.visible }}</span></p>

                    <!-- <div v-for=" (val, key, i) in infos" :key="key">
                        <span class="label">
                            {{ key }}

                        </span>
                        <span class="val"> {{ val }}</span>
                        <button v-if="key == 'id' && val == 34" @click="changeMod">模型切换</button>
                    </div> -->
                </div>
            </div>
        </div>
        <el-button-group class="btngroup">
            <el-button type="primary" @click="app.horizontalRotationControl(.3)" icon="el-icon-back"></el-button>
            <el-button type="primary" @click="app.horizontalRotationControl(-.3)" icon="el-icon-right"></el-button>
            <el-button type="primary" @click="app.resetControl()" icon="el-icon-circle-plus-outline"></el-button>

            <el-button type="primary" @click="app.zoomController(.1)" icon="el-icon-zoom-in "></el-button>

            <el-button type="primary" @click="app.zoomController(-.1)" icon="el-icon-zoom-out"></el-button>
            <el-button type="primary" @click="app.changeMaterial('风')">
                设备高亮
            </el-button>
            <el-button type="primary" @click="app.startAction()">
                动画开始
            </el-button>
            <el-button type="primary" @click="app.stopAction()">
                动画暂停
            </el-button>
            <el-button type="primary" @click="app.addspeed()">
                转速+1
            </el-button>

        </el-button-group>
    </div>
</template>

<script lang="js">

import * as THREE from 'three/build/three.module.js';
import DThree from "@/utils/Three/three.js";
import { loaderModel } from "@/utils/Three/loaderModel.js";


export default {
    name: 'gltfmod',
    components: {

    },
    data() {
        return {
            scene: null,
            camera: null,
            renderer: null,
            cube: null,
            controls: null,
            app: null,
            dialogFlag: false,
            infos: null,
            tranLeft: 0, // 向左偏移
            tranTop: 0, // 向上偏移
            infoBox: false,
            gui: null,
        }
    },
    mounted() {
        this.init('户烘焙缩小模拟OCps贴图.gltf');
    },
    methods: {
        format() {
            return () => {
                return '模型加载中...'
            }
        },
        async init(modUrl) {
            this.app = new DThree('container', {
                background: '@/assets/images/visual/visual_bg.jpg',
                cx: -69,
                cy: 47,
                cz: 0,
                path: "models"
            }, 'per2');


            this.app.initThree();
            this.app.initHelper();
            this.app.initControl();
            this.app.initLight();

            this.scene = this.app.scene;
            this.camera = this.app.camera;
            this.controls = this.app.control;
            this.renderer = this.app.renderer;


            await loaderModel(this.app, modUrl, { x: 0, y: -55, z: -10 })


            const clock = new THREE.Clock();
            this.app.render(() => {
                const data = clock.getDelta();
                this.controls.update(data);
                this.renderer.render(this.scene, this.camera)
            });
            console.log(this.app);
        },
        async changeMod() {
            this.app.clearnModel();
            await
                this.init('户MINI.gltf');


        },

        onMouseClick() {
            this.app.onMouseClick();
        },
        //点击画布
        onMouseClick(event) {
            console.log('touchstart', event)
            this.infoBox = false

            this.tranLeft = (event.clientX + 10) + 'px';
            this.tranTop = (event.clientY + 10) + 'px';
            this.app.onMouseClick(event, this.clickApp);
        },
        // 点击模型
        clickApp(intersects) {
            let self = this;
            if (intersects[0].object !== undefined) {
                const nameNode = intersects[0].object;
                // nameNode.material.color.set(0x808000);//改变1号楼Mesh材质颜色
                console.log(intersects[0].object, '这就是成功点击到的对象了~');
                self.infos = {
                    id: intersects[0].object.id,
                    name: intersects[0].object.name,
                    uuid: intersects[0].object.uuid,
                    visible: intersects[0].object.visible,
                };

                this.infoBox = true

            } else {
                this.infoBox = false

            }
        },
        touchstart(e) {
            console.log('touchstart', e)
        },
    }
}
</script>
<style scoped lang="scss">
.mr-visual {


    .container {
        width: 100%;
        height: 100%;


    }

    #container2 {
        position: absolute;
        width: 400px;
        height: 16px;
        top: 50%;
        left: 50%;
        margin-left: -200px;
        margin-top: -8px;



    }

    .btngroup {

        position: absolute;
        top: 8vh;
        right: 400px;
        z-index: 55;
    }

    .popinfos {

        /* 矩形 411 */
        .title {
            background: url("~@/assets/images/visual/top.png") no-repeat;
            background-size: 100% 100%;
            width: 200px;
            height: 44px;
            color: #fff;
            font-weight: 600;
            line-height: 40px;
            // padding-left: 20px;
            text-indent: 40px;
        }

        .content {
            /* 矩形 447 */
            opacity: 1;
            opacity: 1;
            background: url("~@/assets/images/visual/pop_box_bg.png") no-repeat;
            background-size: 100% 100%;
            padding: 20px 10px;
            line-height: 30px;
        }

        position: absolute;
        width: 264.12px;
        height: 144.56px;
        opacity: 1;
        z-index: 1;
        padding: 20px 15px;




        span {
            width: 210px;
            font-family: "microsoft yahei ui","microsoft yahei";
            font-size: 14px;
            color: rgb(255, 255, 255);
            text-align: left;
            padding-left: 8px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            line-height: 26px;

            font-weight: 600;
        }

        .label,
        .dw {
            font-family: "microsoft yahei ui","microsoft yahei";
            font-size: 14px;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0em;

            font-variation-settings: "opsz" auto;
            color: #FFFFFF;

        }

        .val {
            font-family: "microsoft yahei ui","microsoft yahei";
            font-size: 18px;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0em;

            font-variation-settings: "opsz" auto;
            color: #7DF6FF;
            width: 11em;

            white-space: nowrap;
            /*强制单行显示*/
            text-overflow: ellipsis;
            /*超出部分省略号表示*/
            overflow: hidden;
            /*超出部分隐藏*/
            width: auto;
            max-width: 120px;
            /*设置显示的最大宽度*/
            display: inline-block;
            vertical-align: top
        }
    }
}
</style>