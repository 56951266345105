<template>
  <div class="v-card">
    <v-arrow-bg>
      <template #header>空调模式</template>
      <div class="v-card-container">
        <div
          class="count"
          v-for="i in controlList"
          :key="'control_' + i.id"
          @click="clickControl(i)"
        >
          <el-tooltip
            class="item"
            effect="light"
            :content="i.label"
            placement="top"
          >
            <div
              class="value"
              :class="[ctrlId == i.id ? 'active' : '', 'img' + i.id]"
            ></div>
          </el-tooltip>
        </div>
      </div>
    </v-arrow-bg>
  </div>
</template>

<script lang="js">
import VArrowBg from "../v-arrow-bg.vue";
export default {
  name: 'VLeftCard3',
  components: {
    VArrowBg
  },
  props: {
    propsData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  watch: {
    propsData: {
      deep: true,
      handler() {
        this.screenData = Object.assign({}, this.propsData);
      }
    }
  },
  data() {
    return {
      screenData: {},
      ctrlId: 0,
      controlList: [
        {
          id: 0,
          label: "制冷模式",
          mode: "zhileng"
        },
        {
          id: 1,
          label: "制热模式",
          mode: "zhire"
        },
        {
          id: 2,
          label: "送风模式",
          mode: "songfeng"
        },
        {
          id: 3,
          label: "新风+地面调温",
          mode: "xinfengdimian"
        },
        {
          id: 4,
          label: "新风模式",
          mode: "xinfeng"
        },
        {
          id: 5,
          label: "五恒模式",
          mode: "wuheng"
        },
        {
          id: 6,
          label: "全空气模式",
          mode: "quankongqi"
        }
      ]
    }
  },
  mounted() {
    this.clickControl(this.controlList[0])
  },
  methods: {
    clickControl(i) {
      this.ctrlId = i.id;
      this.$emit("control", i)
    },
  }
}
</script>
<style scoped lang="scss">
.v-card {
  width: 100%;
  height: 172px;
  border-radius: 2px;
  .v-card-container {
    width: 100%;
    height: calc(100% - 32px);
    display: flex;
    flex-wrap: wrap;
    padding: 16px 0;
    box-sizing: border-box;
    
    .count {
      cursor: pointer;
    }

    .value {
      width: 40px;
      height: 40px;
      font-family: "microsoft yahei ui","microsoft yahei";
      font-size: 18px;
      font-weight: 500;
      color: #d9fdee;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      margin-right: 16px;
      margin-bottom: 16px;

      &.img0 {
        background-image: url("../../images/left/zhileng.png");
        &:hover {
          background-image: url("../../images/left/zhileng-hover.png");
        }
        &.active {
          background-image: url("../../images/left/zhileng-active.png");
        }
      }

      &.img1 {
        background-image: url("../../images/left/zhire.png");
        &:hover {
          background-image: url("../../images/left/zhire-hover.png");
        }
        &.active {
          background-image: url("../../images/left/zhire-active.png");
        }
      }

      &.img2 {
        background-image: url("../../images/left/songfeng.png");
        &:hover {
          background-image: url("../../images/left/songfeng-hover.png");
        }
        &.active {
          background-image: url("../../images/left/songfeng-active.png");
        }
      }

      &.img3 {
        background-image: url("../../images/left/tiaowen.png");
        &:hover {
          background-image: url("../../images/left/tiaowen-hover.png");
        }
        &.active {
          background-image: url("../../images/left/tiaowen-active.png");
        }
      }

      &.img4 {
        background-image: url("../../images/left/xinfeng.png");
        &:hover {
          background-image: url("../../images/left/xinfeng-hover.png");
        }
        &.active {
          background-image: url("../../images/left/xinfeng-active.png");
        }
      }

      &.img5 {
        background-image: url("../../images/left/wuheng.png");
        &:hover {
          background-image: url("../../images/left/wuheng-hover.png");
        }
        &.active {
          background-image: url("../../images/left/wuheng-active.png");
        }
      }

      &.img6 {
        background-image: url("../../images/left/quankongqi.png");
        &:hover {
          background-image: url("../../images/left/quankongqi-hover.png");
        }
        &.active {
          background-image: url("../../images/left/quankongqi-active.png");
        }
      }
    }
  }
}
</style>
