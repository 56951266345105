<template>
  <div class="v-company-info">
    <transition name="el-zoom-in-bottom">
      <div :class="['vc-mini', isMini ? '' : 'vc-large']">
        <template v-if="isMini">
          <p class="vc-label">
            <span>{{ infoData.contactPhone }}</span>
            <span @click="showDetail">详情</span>
          </p>
          <p class="vc-name">{{infoData.address}}</p>
        </template>
        <template v-else>
          <div class="vc-large-content">
            <div class="img">
              <img src="~@/assets/images/visual/company.png" alt="">
            </div>

            <div class="progress">
              <span>咨询</span>
              <span>下单</span>
              <span>施工中</span>
              <span>完成</span>
            </div>

            <div class="progress-box">
              <div class="progress-rate"></div>
            </div>

            <div class="cv-info">
              <div class="info-box">
                <div class="label">项目编号</div>
                <div class="cont">{{ infoData.projectCode }}</div>
              </div>
              <div class="info-com">
                <span>{{ infoData.projectName }}</span>
                <!-- <span>家庭</span> -->
              </div>
              <div class="info-address">
                {{ infoData.address }}
              </div>
              <div class="info-box">
                <div class="label">新建日期</div>
                <div class="cont">{{ infoData.createdAt }}</div>
              </div>
              <div class="info-box">
                <div class="label">运营方</div>
                <div class="cont">{{ infoData.operator }}</div>
              </div>
              <div class="info-box">
                <div class="label">联系人</div>
                <div class="cont">{{ infoData.contactPerson }}</div>
              </div>
              <div class="info-box">
                <div class="label">联系方式</div>
                <div class="cont">{{ infoData.contactPhone }}</div>
              </div>
              <!-- <div class="info-box">
                <div class="label">备注</div>
                <div class="cont">宁夏吴忠市红寺堡新宁夏吴忠市红寺堡新宁夏吴忠市红寺堡新...</div>
              </div> -->
              <!-- <div class="info-box">
                <div class="label">客户档案</div>
                <div class="cont">...</div>
              </div> -->
            </div>


            <div class="vc-btn" @click="showDetail">收起</div>
          </div>
        </template>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: "VCompanyInfo",
  data() {
    return {
      isMini: true,
      infoData: {}
    }
  },
  computed: {
    ...mapState(["projectCurrentRowData"]),
  },
  watch: {
    projectCurrentRowData: {
      deep: true,
      handler() {
        this.setInfo()
      }
    }
  },
  methods: {
    showDetail() {
      this.isMini = !this.isMini;
    },
    setInfo() {
      console.log("项目详情---", this.projectCurrentRowData)
      this.infoData = this.projectCurrentRowData;
    }
  }
}
</script>

<style scoped lang="scss">
.v-company-info {
  position: absolute;
  left: 0;
  bottom: 0;


  .vc-name {
    font-family: "microsoft yahei ui","microsoft yahei";
    font-size: 14px;
    color: rgba(255, 255, 255, 0.9);
    text-align: justify;
  }
  .vc-mini {
    width: 320px;
    height: 54px;
    opacity: 1;
    background: rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
    transition: all .2s linear;
    padding: 10px;
    line-height: 32px;
    .vc-label {
      font-family: "microsoft yahei ui","microsoft yahei";
      font-size: 14px;
      font-weight: 500;
      line-height: 12px;
      letter-spacing: 0px;
      color: rgba(255, 255, 255, 0.9);
      display: flex;
      justify-content: space-between;
      >span:nth-child(2) {
        font-family: "microsoft yahei ui","microsoft yahei";
        font-size: 12px;
        font-weight: normal;
        line-height: 12px;
        letter-spacing: 0px;
        font-variation-settings: "opsz" auto;
        color: rgba(255, 255, 255, 0.5);
        cursor: pointer;
      }
    }


    &.vc-large {
      height: auto;
      padding: 24px 20px 0;
    }
  }

  .vc-large-content {
    line-height: 32px;
    .img {
      height: 13.9vh;
      margin-bottom: 24px;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .progress {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
      >span {
        font-family: "microsoft yahei ui","microsoft yahei";
        font-size: 12px;
        color: rgba(255, 255, 255, 0.5);
      }
    }

    .progress-box {
      height: 5.18px;
      border-radius: 106px;
      background: rgba(255, 255, 255, 0.24);
      border-radius: 106px;
      overflow: hidden;
      margin-bottom: 24px;
      .progress-rate {
        height: 100%;
        width: 20%;
        background: #4B53EE;
      }
    }

    .cv-info {
      .info-box {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .label {
          width: 6.25vw;
          font-family: "microsoft yahei ui","microsoft yahei";
          font-size: 14px;
          text-align: left;
          letter-spacing: 0px;
          color: rgba(255, 255, 255, 0.5);
        }
        .cont {
          width: 210px;
          font-family: "microsoft yahei ui","microsoft yahei";
          font-size: 14px;
          color: rgba(255, 255, 255, 0.8);
          text-align: left;
          padding-left: 8px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }

      .info-com {
        display: flex;
        justify-content: space-between;
        align-items: center;
        >span:nth-child(1) {
          font-family: "microsoft yahei ui","microsoft yahei";
          font-size: 16px;
          font-weight: 500;
          color: rgba(255, 255, 255, 0.8);
        }

        >span:nth-child(2) {
          width: 49px;
          height: 20px;
          border-radius: 170px;
          opacity: 1;
          background: rgba(35, 126, 88, 0.26);
          box-sizing: border-box;
          border: 1px solid rgba(8, 214, 128, 0.62);
          color: #008C51;
          font-size: 12px;
          text-align: center;
          line-height: 20px;
        }
      }
      .info-address {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.8);
        position: relative;
        text-align: left;
        &::before {
          content: "";
          display: inline-block;
          width: 14px;
          height: 16px;
          background: url("~@/assets/images/visual/address.png") no-repeat;
          background-size: 100% 100%;
        }
      }
    }

    .vc-btn {
      height: 45px;
      text-align: right;
      line-height: 45px;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.5);
      cursor: pointer;
    }
  }
}
</style>