<template>
  <div class="v-card">
    <v-arrow-bg>
      <template #header>运行模式</template>
      <div class="v-card-container">
        <div class="count-box1">
          <div class="count" @click="clickControl({ id: 0 })">
            <div
              class="value"
              :class="[ctrlId == 0 ? 'active' : '', 'img0']"
            >
              <span>节能模式</span>
            </div>
          </div>
          <div class="count" @click="clickControl({ id: 1 })">
            <div
              class="value"
              :class="[ctrlId == 1 ? 'active' : '', 'img1']"
            >
              <span>舒适模式</span>
            </div>
          </div>
        </div>
        <div class="count-box2">
          <div class="count" @click="clickControl({ id: 2 })">
            <div
              class="value"
              :class="[ctrlId == 2 ? 'active' : '', 'img2']"
            >
              <span>节假日模式</span>
            </div>
          </div>
          <div class="count" @click="clickControl({ id: 3 })">
            <div
              class="value"
              :class="[ctrlId == 3 ? 'active' : '', 'img3']"
            >
              <span>离家模式</span>
            </div>
          </div>
        </div>
        <div class="count-box1">
          <div class="count" @click="clickControl({ id: 4 })">
            <div
              class="value"
              :class="[ctrlId == 4 ? 'active' : '', 'img4']"
            >
              <span>备用模式</span>
            </div>
          </div>
        </div>
      </div>
    </v-arrow-bg>
  </div>
</template>

<script lang="js">
import VArrowBg from "../v-arrow-bg.vue";
export default {
  name: 'VLeftCard4',
  components: {
    VArrowBg
  },
  props: {
    propsData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  watch: {
    propsData: {
      deep: true,
      handler() {
        this.screenData = Object.assign({}, this.propsData);
      }
    }
  },
  data() {
    return {
      screenData: {},
      ctrlId: 0,
      controlList: [
        {
          id: 0,
          label: "节能模式",
          mode: "jieneng"
        },
        {
          id: 1,
          label: "舒适模式",
          mode: "shushi"
        },
        {
          id: 2,
          label: "离家模式",
          mode: "lijia"
        },
        {
          id: 3,
          label: "节假日模式",
          mode: "jiejiqri"
        },
        {
          id: 4,
          label: "备用模式",
          mode: "beiyong"
        },
      ]
    }
  },
  methods: {
    clickControl(i) {
      this.ctrlId = i.id;
      this.$emit("control", i)
    },
  }
}
</script>
<style scoped lang="scss">
.v-card {
  width: 100%;
  height: 172px;
  border-radius: 2px;
  .v-card-container {
    width: 100%;
    height: calc(100% - 32px);
    // display: flex;
    // flex-wrap: wrap;
    padding: 16px 0;
    box-sizing: border-box;

    .count {
      cursor: pointer;
      display: flex;
    }

    .count-box1 {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .count-box2 {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 12px 0;
    }

    .value {
      width: 99px;
      height: 24px;
      line-height: 24px;
      font-family: "microsoft yahei ui","microsoft yahei";
      font-size: 12px;
      font-weight: 500;
      color: #677b80;
      text-align: center;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      box-sizing: border-box;

      &.img0 {
        background-image: url("../../images/left/run-jieneng.png");
        &:hover {
          background-image: url("../../images/left/run-jieneng-hover.png");
        }
        &.active {
          background-image: url("../../images/left/run-jieneng-active.png");
        }
      }

      &.img1 {
        background-image: url("../../images/left/run-shushi.png");
        &:hover {
          background-image: url("../../images/left/run-shushi-hover.png");
        }
        &.active {
          background-image: url("../../images/left/run-shushi-active.png");
        }
      }

      &.img2 {
        background-image: url("../../images/left/run-jiejiari.png");
        &:hover {
          background-image: url("../../images/left/run-jiejiari-hover.png");
        }
        &.active {
          background-image: url("../../images/left/run-jiejiari-active.png");
        }
      }

      &.img3 {
        background-image: url("../../images/left/run-lijia.png");
        &:hover {
          background-image: url("../../images/left/run-lijia-hover.png");
        }
        &.active {
          background-image: url("../../images/left/run-lijia-active.png");
        }
      }

      &.img4 {
        background-image: url("../../images/left/run-beiyong.png");
        &:hover {
          background-image: url("../../images/left/run-beiyong-hover.png");
        }
        &.active {
          background-image: url("../../images/left/run-beiyong-active.png");
        }
      }
    }
  }
}
</style>
