<template>
  <div class="v-card">
    <v-arrow-bg>
      <template #header>光储模式</template>
      <div class="v-card-container">
        <div
          class="count"
          v-for="i in controlList"
          :key="'control_' + i.id"
          @click="clickControl(i)"
        >
          <el-tooltip
            class="item"
            effect="light"
            :content="i.label"
            placement="top"
          >
            <div
              class="value"
              :class="[ctrlId == i.id ? 'active' : '', 'img' + i.id]"
            ></div>
          </el-tooltip>
        </div>
      </div>
    </v-arrow-bg>
  </div>
</template>

<script lang="js">
import VArrowBg from "../v-arrow-bg.vue";
export default {
  name: 'VLeftCard5',
  components: {
    VArrowBg
  },
  props: {
    propsData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  watch: {
    propsData: {
      deep: true,
      handler() {
        this.screenData = Object.assign({}, this.propsData);
      }
    }
  },
  data() {
    return {
      screenData: {},
      ctrlId: 0,
      controlList: [
        {
          id: 0,
          label: "晴天模式",
          mode: "qingtian"
        },
        {
          id: 1,
          label: "阴天模式",
          mode: "yintian"
        },
        {
          id: 2,
          label: "削峰填谷模式",
          mode: "xuefengtiangu"
        },
        {
          id: 3,
          label: "夜晚模式",
          mode: "yewan"
        },
        {
          id: 4,
          label: "计划模式",
          mode: "jihua"
        },
        {
          id: 5,
          label: "充电模式",
          mode: "chongdian"
        },
        {
          id: 6,
          label: "经济模式",
          mode: "jingji"
        },
        {
          id: 7,
          label: "仿真模式",
          mode: "fangzhen"
        },
        {
          id: 8,
          label: "离网模式",
          mode: "liwang"
        }
      ]
    }
  },
  mounted() {
    this.clickControl(this.controlList[0]);
  },
  methods: {
    clickControl(i) {
      this.ctrlId = i.id;
      this.$emit("control", i)
    },
  }
}
</script>
<style scoped lang="scss">
.v-card {
  width: 100%;
  height: 267px;
  border-radius: 2px;
  .v-card-container {
    width: 100%;
    height: calc(100% - 32px);
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    padding: 16px 0;
    box-sizing: border-box;

    .count {
      cursor: pointer;
    }

    .value {
      width: 50px;
      height: 50px;
      line-height: 50px;
      font-family: "microsoft yahei ui","microsoft yahei";
      font-size: 18px;
      font-weight: 500;
      color: #d9fdee;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      margin: 0px 12px 12px;

      &.img0 {
        background-image: url("../../images/left/gc-qingtian.png");
        &:hover {
          background-image: url("../../images/left/gc-qingtian-hover.png");
        }
        &.active {
          background-image: url("../../images/left/gc-qingtian-active.png");
        }
      }

      &.img1 {
        background-image: url("../../images/left/gc-yintian.png");
        &:hover {
          background-image: url("../../images/left/gc-yintian-hover.png");
        }
        &.active {
          background-image: url("../../images/left/gc-yintian-active.png");
        }
      }

      &.img2 {
        background-image: url("../../images/left/gc-xuefeng.png");
        &:hover {
          background-image: url("../../images/left/gc-xuefeng-hover.png");
        }
        &.active {
          background-image: url("../../images/left/gc-xuefeng-active.png");
        }
      }

      &.img3 {
        background-image: url("../../images/left/gc-yewan.png");
        &:hover {
          background-image: url("../../images/left/gc-yewan-hover.png");
        }
        &.active {
          background-image: url("../../images/left/gc-yewan-active.png");
        }
      }

      &.img4 {
        background-image: url("../../images/left/gc-jihua.png");
        &:hover {
          background-image: url("../../images/left/gc-jihua-hover.png");
        }
        &.active {
          background-image: url("../../images/left/gc-jihua-active.png");
        }
      }

      &.img5 {
        background-image: url("../../images/left/gc-chongdian.png");
        &:hover {
          background-image: url("../../images/left/gc-chongdian-hover.png");
        }
        &.active {
          background-image: url("../../images/left/gc-chongdian-active.png");
        }
      }

      &.img6 {
        background-image: url("../../images/left/gc-jingji.png");
        &:hover {
          background-image: url("../../images/left/gc-jingji-hover.png");
        }
        &.active {
          background-image: url("../../images/left/gc-jingji-active.png");
        }
      }

      &.img7 {
        background-image: url("../../images/left/gc-fangzhen.png");
        &:hover {
          background-image: url("../../images/left/gc-fangzhen-hover.png");
        }
        &.active {
          background-image: url("../../images/left/gc-fangzhen-active.png");
        }
      }

      &.img8 {
        background-image: url("../../images/left/gc-liwang.png");
        &:hover {
          background-image: url("../../images/left/gc-liwang-hover.png");
        }
        &.active {
          background-image: url("../../images/left/gc-liwang-active.png");
        }
      }
    }
  }
}
</style>
